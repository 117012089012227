import { ComboboxItem } from '@mantine/core';

import { Maybe } from 'types/utility.types';

import { CustomComboboxItem } from '../CustomSelect/CustomSelect.types';
import { CONFIGURABLE_FILTER_TYPES, DEFAULT_FILTERS } from './filters.config';

/**
 * TODO: We would like to extends filter configs with components props
 * when we have more props exported from momentum library
 * export interface RangeFilterConfig extends BaseFilterConfig, SlideProps {
 *   key: string,
 *   component: FILTER_TYPES.range;
 * }
 */

export const ALL_VALUE = 'All';

export type DefaultFilters = keyof typeof DEFAULT_FILTERS;
export type ConfigurableFilterTypes = keyof typeof CONFIGURABLE_FILTER_TYPES;
export type DynamicDataFilterTypes = Extract<
  DefaultFilters,
  'positions' | 'majors'
>;
export type DynamicDataGetHook = () => {
  data: any[] | undefined;
  isLoading: boolean;
  isError: boolean;
};

export interface FilterAdditionalDetails {
  key: string;
  description: string;
  rating?: number;
}

export interface TooltipContent {
  title: string;
  additionalDetails?: FilterAdditionalDetails[];
}

export interface TooltipData {
  content: TooltipContent;
}

export interface FilterChangeValue {
  name: string;
  value: any;
}

export interface BaseFilterConfig {
  name: string;
  label: string;
  onChange?: (value: FilterChangeValue) => void;
  onClear?: () => void;
  placeholder?: string;
  disabled?: boolean;
  tooltip?: TooltipData;
}

export interface DynamicDataConfig {
  dynamic: boolean;
}

export interface FilterData<T> {
  data?: Maybe<T>;
  value?: unknown;
}

export const isDynamicFilterConfig = (
  filterConfig: IndividualFilterConfig,
): filterConfig is MultiSelectFilterConfig => {
  return 'dynamic' in filterConfig && filterConfig.dynamic;
};

// Main filter interfaces
export interface SelectFilterConfig
  extends BaseFilterConfig,
    DynamicDataConfig,
    FilterData<ComboboxItem[]> {
  component: 'select';
  value: string;
}

export interface MultiSelectFilterConfig
  extends BaseFilterConfig,
    DynamicDataConfig,
    FilterData<MultiSelectComboboxItem[]> {
  component: 'multiselect';
  value?: string[];
}

export interface MultiSelectGroupFilterConfig
  extends BaseFilterConfig,
    DynamicDataConfig,
    FilterData<MultiSelectComboboxItemGroup[]> {
  component: 'multiselect';
  value: string[];
}

export interface CheckboxFilterConfig extends BaseFilterConfig {
  component: 'boolean';
  value: boolean;
}

export interface RangeFilterConfig
  extends BaseFilterConfig,
    ApiFilterAdditionalConfig {
  component: 'range';
  value: [number, number];
}

export interface RangeSelectFilterConfig
  extends BaseFilterConfig,
    ApiFilterAdditionalConfig,
    FilterData<ComboboxItem[]> {
  component: 'rangeSelect';
  value: [number, number];
}

export interface GroupFilterConfig {
  heading?: string;
  filters: IndividualFilterConfig[];
  filtersLimit?: number;
}

export interface MultiSelectComboboxItem extends CustomComboboxItem {}

export interface MultiSelectComboboxItemGroup {
  group: string;
  items: MultiSelectComboboxItem[];
}

export type IndividualFilterConfig =
  | SelectFilterConfig
  | MultiSelectFilterConfig
  | MultiSelectGroupFilterConfig
  | RangeFilterConfig
  | RangeSelectFilterConfig
  | CheckboxFilterConfig;

export type FiltersConfig = (IndividualFilterConfig | GroupFilterConfig)[];

export type FilterTypeComponentMap = {
  [K in ConfigurableFilterTypes]: (props: any) => JSX.Element;
};

export type FilterDynamicDataGetFuncMap = {
  [K in DynamicDataFilterTypes]: DynamicDataGetHook;
};

export interface ApiFilter {
  name: string;
  componentType: ConfigurableFilterTypes;
  label: string;
  searchXKey: string;
  operator: string;
  groupName: string;
  dynamic: boolean;
  value: any;
  data: Maybe<any[]>;
  placeholder?: string;
  additionalConfig: Maybe<ApiFilterAdditionalConfig>;
  tooltip?: TooltipData;
}

export interface ApiFilterAdditionalConfig {
  min: number;
  max: number;
  step: number;
  minRange: number;
  unit?: string;
}
