import { Menu } from '@mantine/core';

import { SportSelectorButton } from 'app/components/SportSelector/SportSelectorButton/SportSelectorButton.component';
import { useSportSelector } from 'app/components/SportSelector/useSportSelector';

export const SportSelectorMenu = () => {
  const {
    sports,
    selectedSportName,
    isSportSelectorVisible,
    handleUpdateSelectedSport,
    isDesktop,
  } = useSportSelector();

  if (!isSportSelectorVisible || !isDesktop) {
    return null;
  }

  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <SportSelectorButton selectedSportName={selectedSportName} />
      </Menu.Target>

      <Menu.Dropdown>
        {sports.map(sport => {
          return (
            <Menu.Item
              key={sport.slug}
              component="div"
              onClick={() => handleUpdateSelectedSport(sport.slug)}
              aria-label={sport.slug}
            >
              {sport.sportName}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
};
