import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconEyeCross = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 16"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M0.655473 8.03454C0.646186 8.02274 0.637149 8.01122 0.628363 8C0.637149 7.98878 0.646186 7.97726 0.655473 7.96546C0.823029 7.75255 1.07157 7.44658 1.39242 7.0786C2.03472 6.34195 2.96373 5.36034 4.11005 4.37999C6.4179 2.40631 9.53388 0.5 12.9231 0.5C16.3123 0.5 19.4283 2.40631 21.7361 4.37999C22.8824 5.36034 23.8114 6.34195 24.4537 7.0786C24.7746 7.44658 25.0231 7.75255 25.1907 7.96546C25.2 7.97726 25.209 7.98878 25.2178 8C25.209 8.01122 25.2 8.02274 25.1907 8.03454C25.0231 8.24745 24.7746 8.55342 24.4537 8.9214C23.8114 9.65805 22.8824 10.6397 21.7361 11.62C19.4283 13.5937 16.3123 15.5 12.9231 15.5C9.53388 15.5 6.4179 13.5937 4.11005 11.62C2.96373 10.6397 2.03472 9.65805 1.39242 8.9214C1.07157 8.55342 0.823029 8.24745 0.655473 8.03454Z"
          fill={fill}
          stroke={stroke}
          strokeLinejoin="round"
        />
        <circle
          cx="12.9231"
          cy="7.99989"
          r="5.03846"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M0.615388 15.3845L25.2308 0.615291"
          stroke={stroke}
          strokeLinecap="round"
        />
      </svg>
    );
  },
);
IconEyeCross.displayName = 'IconEyeCross';
