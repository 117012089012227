import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconShare = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 16"
        width={width ?? size}
        height={height ?? size}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <path
          id="Vector"
          d="M3.73535 3.53677L6.16182 1M6.16182 1L8.58829 3.53677M6.16182 1L6.16182 11.1471"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 40"
          d="M3.25 5.77537L0.5 5.77537L0.5 15L11.5 15L11.5 5.77537L8.29167 5.77537"
          stroke={stroke}
        />
      </svg>
    );
  },
);
IconShare.displayName = 'IconShare';
