import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconTriangleUp = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 10"
        width={width ?? size}
        height={height ?? size}
        fill="none"
        {...props}
        ref={forwardedRef}
        role="graphics-symbol"
      >
        <path
          d="M4.99994 1.25L10 7.5L-3.50927e-07 7.5L4.99994 1.25Z"
          fill={fill}
        />
      </svg>
    );
  },
);
IconTriangleUp.displayName = 'IconTriangleUp';
