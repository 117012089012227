import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconSearch = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        viewBox="0 0 17 16"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <g clipPath="url(#clip0_2029_9641)">
          <circle
            cx="11.1212"
            cy="5.15152"
            r="4.40152"
            stroke={stroke}
            strokeWidth="1.5"
          />
          <path
            d="M1.19696 14.0151L0.666635 14.5455L1.72729 15.6061L2.25763 15.0758L1.19696 14.0151ZM7.25757 7.95453L1.19696 14.0151L2.25763 15.0758L8.31823 9.01519L7.25757 7.95453Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_2029_9641">
            <rect width="17" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);
IconSearch.displayName = 'IconSearch';
