import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { searchApi } from 'services/search.service';
import { storageApi } from 'services/storage.service';

import { CoachSport } from 'types/coach.types';
import { SearchCoachByEmailOutput } from 'types/search.types';

import { CoachSportsState } from './coachSports.slice.types';

const LAST_SELECTED_SPORT_KEY = 'lastSelectedSportKey';

export const initialState: CoachSportsState = {
  sports: [],
  selectedSport: {
    id: '',
    slug: '',
    sportName: '',
    defaultSearchID: null,
    fasttrackSportID: '',
    preferredPrecollegeTeamType: 'high_school',
    positionLabel: 'position',
    ecosystemReferenceID: '',
    division: '',
  },
};

const slice = createSlice({
  name: 'coachSports',
  initialState,
  reducers: {
    setCoachSports: (
      state,
      { payload: { sports } }: PayloadAction<{ sports: CoachSport[] }>,
    ) => {
      state.sports = sports;
    },
    setCoachSelectedSport: (
      state,
      { payload: { sport } }: PayloadAction<{ sport: CoachSport }>,
    ) => {
      state.selectedSport = sport;
      storeSelectedSportInLocalStorage(sport);
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      searchApi.endpoints.getCoachByEmail.matchFulfilled,
      (state, { payload: coachData }) => {
        extractCoachSportData(coachData, state);
      },
    );
    builder.addMatcher(
      storageApi.endpoints.postCoach.matchFulfilled,
      (state, { payload: coachData }) => {
        extractCoachSportData(coachData, state);
      },
    );
    builder.addMatcher(
      storageApi.endpoints.putSavedSearch.matchFulfilled,
      (state, { payload }) => {
        const { default: isSearchDefault, ...savedSearch } = payload;
        state.sports = state.sports.map(sport => {
          if (sport.slug === state.selectedSport.slug) {
            sport.defaultSearchID = isSearchDefault ? savedSearch.id : '';
          }

          return sport;
        });

        state.selectedSport.defaultSearchID = isSearchDefault
          ? savedSearch.id
          : '';
      },
    );
    builder.addMatcher(
      storageApi.endpoints.postSaveSearch.matchFulfilled,
      (state, { payload }) => {
        const { default: isSearchDefault, ...savedSearch } = payload;
        if (isSearchDefault) {
          state.sports = state.sports.map(sport => {
            if (sport.slug === state.selectedSport.slug) {
              sport.defaultSearchID = savedSearch.id;
            }

            return sport;
          });
          state.selectedSport.defaultSearchID = savedSearch.id;
        }
      },
    );
    builder.addMatcher(
      storageApi.endpoints.deleteSavedSearch.matchFulfilled,
      (state, { meta }) => {
        const {
          originalArgs: { default: isDefault },
        } = meta.arg;
        if (isDefault) {
          state.sports = state.sports.map(sport => {
            if (sport.slug === state.selectedSport.slug) {
              sport.defaultSearchID = '';
            }

            return sport;
          });

          state.selectedSport.defaultSearchID = '';
        }
      },
    );
  },
});

export const { actions: coachSportsActions } = slice;

export const useCoachSportsSlice = () => {
  return { actions: slice.actions };
};

export default slice.reducer;

const extractCoachSportData = (
  coachData: SearchCoachByEmailOutput,
  state: CoachSportsState,
) => {
  if (coachData) {
    const { sports } = coachData;
    state.sports = sports;
    updateSelectedSport(sports, state);
  }
};

const updateSelectedSport = (sports: CoachSport[], state: CoachSportsState) => {
  const selectedSportSlug =
    getSelectedSportFromSessionStorage(sports) || sports[0];
  state.selectedSport = selectedSportSlug;
  storeSelectedSportInLocalStorage(selectedSportSlug);
};

const getSelectedSportFromSessionStorage = (
  sports: CoachSport[],
): CoachSport | undefined => {
  const storedSportSlug = localStorage.getItem(LAST_SELECTED_SPORT_KEY);
  if (storedSportSlug) {
    return sports.find(sport => sport.slug === storedSportSlug);
  }

  return undefined;
};

const storeSelectedSportInLocalStorage = (sport: CoachSport) => {
  try {
    localStorage.setItem(LAST_SELECTED_SPORT_KEY, sport.slug);
  } catch (error) {
    console.error('Error storing selected sport in session storage:', error);
  }
};
