import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconCheck = forwardRef<SVGSVGElement, IconProps>(
  (
    { stroke = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 7 7"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M0.75 4.22368L3.24199 6.25L6.25 0.75"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
IconCheck.displayName = 'IconCheck';
