import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const ICON_INTERESTED_TEST_ID = 'icon-check';

export const IconInterested = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill, stroke = 'white', size = 12, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
        data-testid={ICON_INTERESTED_TEST_ID}
      >
        <circle cx="6" cy="6" r="6" fill={fill} />
        <path
          d="M3.59961 6.63168L5.77444 8.4001L8.39961 3.6001"
          stroke={stroke}
          strokeLinecap="square"
        />
      </svg>
    );
  },
);
IconInterested.displayName = 'IconInterested';
