import { DEFAULT_SORT, ExtendedSortValue } from 'app/utils/search.constants';

import { SortOrderBuildStr } from 'app/components/Table/Table.component.types';

export const buildSortKey = ({
  sortKey,
  sortOrder,
  additionalProperties,
  defaultOptions,
}: SortOrderBuildStr<ExtendedSortValue> = {}): string => {
  let sortList = new Map(DEFAULT_SORT);

  if (defaultOptions && defaultOptions.size) {
    sortList = new Map(defaultOptions);
  }

  if (sortKey && sortOrder) {
    const sortValue: ExtendedSortValue = { sortOrder, additionalProperties };

    // If the sortKey is not one of the defaults, add it at the beginning
    if (!DEFAULT_SORT.has(sortKey)) {
      sortList = new Map([[sortKey, sortValue], ...sortList]);
    } else {
      // If the sortKey is a default, update its sortOrder
      sortList.set(sortKey, sortValue);
    }
  }

  return Array.from(sortList)
    .map(([key, value]) => {
      const additionalProps =
        value.additionalProperties && value.additionalProperties.length > 0
          ? `:${value.additionalProperties.join(':')}`
          : '';
      return `${key}:${value.sortOrder}${additionalProps}`;
    })
    .join(',');
};
