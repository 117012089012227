import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconEllipsisVertical = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4 20"
        width={width ?? size}
        height={height ?? size}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <ellipse cx="2" cy="2" rx="2" ry="2" transform="rotate(90 2 2)" />
        <ellipse cx="2" cy="10" rx="2" ry="2" transform="rotate(90 2 10)" />
        <ellipse cx="2" cy="18" rx="2" ry="2" transform="rotate(90 2 18)" />
      </svg>
    );
  },
);
IconEllipsisVertical.displayName = 'IconEllipsisVertical';
