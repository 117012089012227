import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconPhone = forwardRef<SVGSVGElement, IconProps>(
  (
    { stroke = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <path
          id="Vector"
          d="M6.61875 2.78776L6.61928 2.78895C6.69581 2.96036 6.71867 3.15109 6.68478 3.33589C6.65089 3.52068 6.56188 3.69064 6.42974 3.82335L5.83936 4.41521L5.83933 4.41517L5.83457 4.42008C5.52556 4.73845 5.33591 5.15385 5.29741 5.59594C5.25897 6.03741 5.37357 6.47875 5.62206 6.84555L6.03922 7.4693C6.69143 8.47282 7.53569 9.33685 8.52322 10.0114L8.52631 10.0135L9.15259 10.4344L9.15424 10.4355C9.52394 10.6819 9.96743 10.7926 10.4095 10.7486C10.8515 10.7046 11.2645 10.5088 11.5786 10.1945L11.5789 10.1942L12.191 9.58059C12.1952 9.5764 12.1993 9.57214 12.2033 9.5678C12.3323 9.42893 12.5006 9.33329 12.6853 9.29361C12.8677 9.25444 13.0575 9.27161 13.2299 9.34281L14.1184 9.75282L14.1184 9.75283L14.1211 9.75408C14.4666 9.91094 14.769 10.1495 15.0026 10.4493C15.2361 10.7492 15.3938 11.1014 15.4619 11.4759C15.53 11.8504 15.5066 12.2358 15.3936 12.5992C15.2807 12.9624 15.0816 13.2927 14.8137 13.5618C14.8137 13.5619 14.8136 13.5619 14.8136 13.5619C14.8135 13.562 14.8135 13.562 14.8134 13.5621L14.4009 13.9756C14.4009 13.9756 14.4009 13.9756 14.4009 13.9756C13.4748 14.9038 12.2333 15.4468 10.9252 15.4963C9.6171 15.5458 8.33843 15.098 7.34525 14.2425C7.32984 14.2292 7.31364 14.2169 7.29674 14.2056L7.27889 14.1937C5.31334 12.508 3.48092 10.6723 1.7981 8.7029C1.78976 8.69313 1.78104 8.6837 1.77197 8.67461L1.74291 8.64547C0.89714 7.65164 0.454546 6.37497 0.503696 5.06865C0.553048 3.75692 1.09456 2.51249 2.01952 1.58418L2.4164 1.19341L2.41642 1.19342L2.41982 1.19001C2.68864 0.920178 3.01876 0.719986 3.38169 0.60656C3.74462 0.493136 4.12957 0.469845 4.50343 0.538675C4.8773 0.607505 5.22898 0.766414 5.52818 1.00182C5.82717 1.23708 6.06481 1.5417 6.22055 1.88957C6.22066 1.88982 6.22077 1.89006 6.22088 1.8903L6.61875 2.78776Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
IconPhone.displayName = 'IconPhone';
