import { forwardRef } from 'react';

import { IconProps } from '../icons.component.types';

export const IconVimeo = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 16, width, height, ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <path
          clipRule="evenodd"
          d="M15.999.002c8.837 0 16 7.164 16 16 0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.836 7.163-16 16-16z"
          fill="#86C9EF"
          fillRule="evenodd"
        />
        <path
          d="M6.999 13.002c1.496-1.194 3.623-3.816 5.282-3.816 1.657 0 2.064 1.957 2.29 3.732.229 1.775.88 5.658 1.756 5.658.878 0 2.877-3.435 2.763-4.762-.112-1.327-1.56-1.128-2.356-.747.732-2.654 2.422-4.065 4.583-4.065 2.162 0 2.683 1.527 2.683 3.402 0 2.854-5.785 11.598-9.28 11.598-2.162 0-2.698-3.186-3.136-4.763-.439-1.575-1.301-5.708-2.309-5.973-.391-.065-1.495.812-1.495.812l-.781-1.076z"
          fill="#fff"
        />
      </svg>
    );
  },
);
IconVimeo.displayName = 'IconYoutube';
