import { SortOrder } from 'app/components/Table/Table.component.types';

export const SORT_ORDERS = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type ExtendedSortValue = {
  sortOrder: string;
  additionalProperties?: string[];
};
export const DEFAULT_SORT = new Map<string, ExtendedSortValue>([
  [
    'star_rating',
    { sortOrder: SORT_ORDERS.desc, additionalProperties: ['missing_last'] },
  ],
  [
    'ncsa_program_id',
    { sortOrder: SORT_ORDERS.desc, additionalProperties: ['missing_last'] },
  ],
  [
    'client_last_login',
    { sortOrder: SORT_ORDERS.desc, additionalProperties: ['missing_last'] },
  ],
]);

export const DEFAULT_INTERESTED_SORT = (ipedsID: string) =>
  new Map<string, ExtendedSortValue>([
    [
      'college_interest.interested_at',
      {
        sortOrder: SORT_ORDERS.desc,
        additionalProperties: [
          'nested',
          'college_interest',
          'college_interest.ipeds_id',
          `${ipedsID}`,
          'missing_last',
        ],
      },
    ],
  ]);

export const INTERESTED_SEARCH_PAGE_SIZE = 40;

export const isAscendingSortOrder = (sortOrder?: SortOrder) =>
  sortOrder === SORT_ORDERS.asc;
export const isDescendingSortOrder = (sortOrder?: SortOrder) =>
  sortOrder === SORT_ORDERS.desc;
