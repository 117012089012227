import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootState.type';

import { initialState } from './coachSports.slice';

const selectSlice = (state: RootState) => state.coachSports || initialState;

export const selectCoachSports = createSelector(
  [selectSlice],
  state => state.sports,
);

export const selectCoachSelectedSport = createSelector(
  [selectSlice],
  state => state.selectedSport,
);
