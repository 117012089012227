export const stringifyObjectValues = <T extends Record<string, any>>(
  obj: T,
) => {
  return Object.entries(obj).reduce(
    (agg, [key, value]) => {
      return {
        ...agg,
        [key]: JSON.stringify(value),
      };
    },
    {} as Record<keyof T, string>,
  );
};

export const mapObjectToKeyValue = <T extends Record<string, any>>(
  obj: T,
): Array<{ [K in keyof T]: T[K] }> => {
  return Object.entries(obj).map(([key, value]) => ({
    [key]: value,
  })) as Array<{ [K in keyof T]: T[K] }>;
};

export const parseObjectValues = <T extends Record<string, any>>(obj: T) => {
  return Object.entries(obj).reduce(
    (agg, [key, value]) => {
      return {
        ...agg,
        [key]: JSON.parse(value),
      };
    },
    {} as Record<keyof T, any>,
  );
};

export const removeObjectEmptyValues = <T extends Record<string, any>>(
  obj: T,
) => {
  const result: T = {} as T;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value !== '' || (Array.isArray(value) && value.length > 0)) {
        result[key] = value;
      }
    }
  }

  return result;
};

export const isValidArray = (arr: unknown): boolean =>
  Array.isArray(arr) && arr.length > 0;

export const convertStringsToNumbers = (values: string[]) =>
  values
    .filter((value: string) => !Number.isNaN(Number(value)))
    .map((value: string) => Number(value));
