import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconRadioSelected = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      fill = 'currentColor',
      stroke = 'currentColor',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        {...props}
        ref={forwardedRef}
      >
        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="none" />
        <circle
          cx="10"
          cy="10"
          r="7"
          fill={fill}
          stroke={fill}
          className="svg-inner-icon"
        />
      </svg>
    );
  },
);
IconRadioSelected.displayName = 'IconRadioSelected';
