import classNames from 'classnames';
import { forwardRef } from 'react';

import {
  ButtonProps,
  Button as MantineButton,
  createPolymorphicComponent,
} from '@mantine/core';

export type ButtonSizes = 'small' | 'large';
export type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'link';
export type ButtonColors = 'error' | 'black';

interface BaseButtonProps extends Omit<ButtonProps, 'size'> {
  color?: ButtonColors; // Use ButtonColors type for color prop
}

export interface CommonButtonProps extends BaseButtonProps {
  size?: ButtonSizes;
  variant?: Exclude<ButtonVariants, 'link'>;
}

export interface LinkButtonProps extends BaseButtonProps {
  variant?: 'link';
  'aria-label'?: string;
  'aria-labelledby'?: string;
}

export const Button = createPolymorphicComponent<
  'button',
  CommonButtonProps | LinkButtonProps
>(
  forwardRef<HTMLButtonElement, CommonButtonProps | LinkButtonProps>(
    (props, ref) => {
      const isCommonButtonProps = (
        props: CommonButtonProps | LinkButtonProps,
      ): props is CommonButtonProps => {
        return props.variant !== 'link';
      };

      const {
        variant = 'primary',
        color,
        disabled,
        className,
        ...restProps
      } = props;

      const size = isCommonButtonProps(props) ? props.size : undefined;

      const classList = classNames(className, {
        disabled,
        [`color-${color}`]: color,
      });

      return (
        <MantineButton
          {...restProps}
          ref={ref}
          variant={variant}
          size={size}
          color={color}
          className={classList}
          disabled={disabled}
          loaderProps={{ type: 'dots' }}
        />
      );
    },
  ),
);
Button.displayName = 'Button';

export interface SelectionButtonProps extends Omit<CommonButtonProps, 'color'> {
  selected?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const SelectionButton = forwardRef<
  HTMLButtonElement,
  SelectionButtonProps
>(
  (
    {
      variant = 'primary',
      selected = false,
      size = 'small',
      disabled,
      className,
      ...props
    },
    ref,
  ) => {
    const classList = classNames(className, 'color-selection', {
      active: selected,
      disabled,
    });

    return (
      <MantineButton
        {...props}
        ref={ref}
        variant={variant}
        size={size}
        className={classList}
        disabled={disabled}
      />
    );
  },
);
