import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconFilter = forwardRef<SVGSVGElement, IconProps>(
  (
    { stroke = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M4.33337 1.95239H14.9592"
          stroke={stroke}
          strokeLinecap="square"
        />
        <path
          d="M11.8976 8.04761H14.9591"
          stroke={stroke}
          strokeLinecap="square"
        />
        <path
          d="M1.04089 8.04761H8.66671"
          stroke={stroke}
          strokeLinecap="square"
        />
        <path
          d="M1.04077 14.1428H2.95914"
          stroke={stroke}
          strokeLinecap="square"
        />
        <path
          d="M6.52117 14.1428H14.9592"
          stroke={stroke}
          strokeLinecap="square"
        />
        <circle cx="2.66672" cy="1.90476" r="1.40476" stroke={stroke} />
        <circle cx="10.2857" cy="7.99998" r="1.40476" stroke={stroke} />
        <circle cx="4.95237" cy="14.0952" r="1.40476" stroke={stroke} />
      </svg>
    );
  },
);
IconFilter.displayName = 'IconFilter';
