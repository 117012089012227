import { convertToMilliseconds } from 'app/utils/convertToMilliseconds.function';
import { useCallback, useEffect, useState } from 'react';

import { TimeUnit } from 'types/idle.types';

interface UseInactivityRefresherOptions {
  interval: number;
  unit: TimeUnit;
}

export const useInactivityRefresh = ({
  interval,
  unit,
}: UseInactivityRefresherOptions) => {
  const [lastInactiveTime, setLastInactiveTime] = useState<Date | null>(null);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      setLastInactiveTime(new Date());
    } else if (document.visibilityState === 'visible' && lastInactiveTime) {
      const now = new Date();
      const inactiveDuration = now.getTime() - lastInactiveTime.getTime();
      const inactivityThreshold = convertToMilliseconds(interval, unit);

      if (inactiveDuration > inactivityThreshold) {
        window.location.reload();
      }
    }
  }, [lastInactiveTime, interval, unit]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);
};
