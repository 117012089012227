import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconAscender = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 400 400"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <polygon
          fill={fill}
          points="317.48,306.74 277.58,105.08 280.85,304.75 280.67,304.82 242.02,64.78 234.99,241.75 232.11,321.62 
	231.26,313.33 216.98,175.65 200.47,16.35 184,175.65 169.77,313.33 168.86,321.62 166.03,241.75 157.85,65.74 120.14,304.2 
	123.38,105.08 83.5,306.69 200.47,367.67 "
        />
      </svg>
    );
  },
);
