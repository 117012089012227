import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconClose = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 17"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.88805 0.534704L1.37019 -0.0078125L0.285156 1.0279L0.803013 1.57042L6.96343 8.02418L0.803499 14.4774L0.285642 15.02L1.37068 16.0557L1.88853 15.5132L8.00026 9.11039L14.112 15.5132L14.6299 16.0557L15.7149 15.02L15.197 14.4774L9.0371 8.02418L15.1975 1.57042L15.7154 1.0279L14.6303 -0.0078125L14.1125 0.534704L8.00026 6.93798L1.88805 0.534704Z"
          fill={fill}
        />
      </svg>
    );
  },
);
IconClose.displayName = 'IconClose';
