/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { useAppSelector } from 'hooks/useAppSelector';
import { useInactivityRefresh } from 'hooks/useInactivityRefresh.hook';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import '@mantine/core/styles.css';

import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';

import { Header } from 'app/components/Header';
import { Router } from 'app/components/Router';

import { selectEngagementID } from 'store/slices/engagement/engagement.slice.selectors';
import { selectCoachAndSelectedSport } from 'store/slices/shared/coachSport.selectors';

import { environment } from 'utils/runtime-environment/RuntimeEnvironment';

import 'styles/_global.scss';

import { Auth0User } from 'types/auth0.types';
import { DEFAULT_IDLE_TIMEOUT_CONFIGURATION } from 'types/idle.types';

import { ExternalScripts } from './components/ExternalScripts';

import 'react-image-crop/src/ReactCrop.scss';

const DEFAULT_LANG = 'en';

export const App = () => {
  const { inactive: inactiveConfig } = environment.env
    .refreshConfigurations ?? {
    inactive: DEFAULT_IDLE_TIMEOUT_CONFIGURATION,
  };
  const { coach } = useAppSelector(selectCoachAndSelectedSport);
  const { user } = useAuth0<Auth0User>();
  const engagementID = useAppSelector(selectEngagementID);

  useInactivityRefresh(inactiveConfig);

  useEffect(() => {
    datadogRum.setUser({
      auth0ID: user?.sub,
      coachID: coach.id,
      email: user?.email,
      engagementID,
    });
  }, [user?.email, user?.sub, coach.id, engagementID]);

  return (
    <>
      <Helmet
        titleTemplate="%s | NCSA Coach"
        defaultTitle="Home"
        htmlAttributes={{ lang: DEFAULT_LANG }}
      >
        <meta
          name="description"
          content="A coach-first application by NCSA and IMG"
        />
      </Helmet>
      <>
        <Header />
        <main>
          <Router />
        </main>
      </>
      <ExternalScripts />
    </>
  );
};
