import { LoadingOverlay, LoadingOverlayProps } from '@mantine/core';

export const LoadingOverlayWrapper = (props: LoadingOverlayProps) => (
  <LoadingOverlay
    overlayProps={{ opacity: 0 }}
    {...props}
    role="status"
    aria-live="polite"
  />
);
