/**
 *
 * MetaText Component
 *
 */
import classNames from 'classnames';
import { forwardRef } from 'react';

import { Box, createPolymorphicComponent } from '@mantine/core';

import './MetaText.component.scss';

export type MetaTextSize = 'small' | 'medium' | 'large';

interface MetaTextProps extends React.PropsWithChildren {
  className?: string;
  size?: MetaTextSize;
  condensed?: boolean;
  dimmed?: boolean;
}

export const MetaText = createPolymorphicComponent<'span', MetaTextProps>(
  forwardRef<HTMLSpanElement, MetaTextProps>(
    (
      { children, className, condensed, dimmed, size = 'small', ...others },
      ref,
    ) => {
      const classList = classNames(
        'meta-text',
        `meta-text--${size}`,
        {
          condensed,
          dimmed,
        },
        className,
      );

      return (
        <Box className={classList} component="span" {...others} ref={ref}>
          {children}
        </Box>
      );
    },
  ),
);
MetaText.displayName = 'MetaText';
