import { createSelector } from '@reduxjs/toolkit';

import { Coach } from 'types/coach.types';

import {
  selectCoachSelectedSport,
  selectCoachSports,
} from '../coachSports/coachSports.slice.selectors';

export const selectCoachAndSelectedSport = createSelector(
  [state => state.coach as Coach, selectCoachSelectedSport],
  (coach, sport) => ({
    coach,
    sport,
  }),
);

export const selectCoachAndSports = createSelector(
  [state => state.coach as Coach, selectCoachSports],
  (coach, sports) => ({
    coach,
    sports,
  }),
);
