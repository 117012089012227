import { NavLinkProps } from 'react-router-dom';

export type ExtendedNavLinkProps = NavLinkProps & {
  label: string;
};

export const MAIN_NAV_LINKS: ExtendedNavLinkProps[] = [
  { to: '/', label: 'Interested' },
  { to: '/search', label: 'Search' },
  { to: '/lists', label: 'Lists' },
];

const SETTINGS_BASE_PATH = '/settings';
export const SETTINGS_NAV_LINKS: ExtendedNavLinkProps[] = [
  { to: `${SETTINGS_BASE_PATH}/profile`, label: 'My Profile' },
  { to: `${SETTINGS_BASE_PATH}/searches`, label: 'Saved Searches' },
  { to: `${SETTINGS_BASE_PATH}/preferences`, label: 'Preferences' },
  { to: `${SETTINGS_BASE_PATH}/needs`, label: 'Recruiting Needs' },
];
