import { forwardRef } from 'react';

import { IconProps } from '../icons.component.types';

export const IconYoutube = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 16, width, height, ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 0C23.2783 0 30 6.71266 30 15C30 23.2783 23.2873 30 15 30C6.72166 30 0 23.2873 0 15C0 6.72166 6.71266 0 15 0Z"
          fill="#CF1D13"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 12C6 10.3431 7.34315 9 9 9H21C22.6569 9 24 10.3431 24 12V19C24 20.6569 22.6569 22 21 22H9C7.34315 22 6 20.6569 6 19V12Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 13V18.0682L17.7043 15.5341L13 13Z"
          fill="#CF1D13"
        />
      </svg>
    );
  },
);
IconYoutube.displayName = 'IconYoutube';
