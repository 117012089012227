import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconInfo = forwardRef<SVGSVGElement, IconProps>(
  ({ fill = 'none', size = 16, width, height, ...props }, forwardedRef) => {
    return (
      <svg
        role="graphics-symbol"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <circle cx="6" cy="6" r="6" fill={fill} />
        <path
          d="M6.35711 4.19655C6.63325 4.19655 6.85711 4.4204 6.85711 4.69655V9.78554C6.85711 10.0617 6.63325 10.2855 6.35711 10.2855H5.64282C5.36668 10.2855 5.14282 10.0617 5.14282 9.78554V4.69655C5.14282 4.4204 5.36668 4.19655 5.64282 4.19655H6.35711ZM6.85711 2.9284C6.85711 3.20454 6.63325 3.4284 6.35711 3.4284H5.64282C5.36668 3.4284 5.14282 3.20454 5.14282 2.9284V2.21411C5.14282 1.93797 5.36668 1.71411 5.64282 1.71411H6.35711C6.63325 1.71411 6.85711 1.93797 6.85711 2.21411V2.9284Z"
          fill="white"
        />
      </svg>
    );
  },
);
IconInfo.displayName = 'IconInfo';
