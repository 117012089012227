import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  convertStringsToNumbers,
  isValidArray,
} from 'app/utils/object.functions';
import { SCREENS } from 'app/utils/screen.constants';
import { DEFAULT_INTERESTED_SORT } from 'app/utils/search.constants';

import {
  ApiFilter,
  MultiSelectComboboxItem,
} from 'app/components/Filters/filters.types';
import { SETUP_FORM_INITIAL_VALUES } from 'app/components/SetupStepsScreen/SetupSteps/SetupSteps.constants';
import { SetupStepsFormValues } from 'app/components/SetupStepsScreen/SetupSteps/SetupSteps.context';
import { TableColumn } from 'app/components/Table/Table.component.types';

import { coachActions } from 'store/slices/coach/coach.slice';
import { initialState } from 'store/slices/coachSavedSearch/coachSavedSearch.constants';

import { getErrorMessage } from 'utils/error';

import { Activity, prepareActivityPayload } from 'types/activity.types';
import { Athlete, AthleteEmail, AthleteNote } from 'types/athlete.types';
import { BaseCoachAthleteActivity, BaseCoachID } from 'types/base.types';
import { College } from 'types/college.types';
import { ListAthleteData } from 'types/list.types';
import {
  AthleteQueryInput,
  BaseSearchInput,
  BaseSportInput,
  CoachSportPreferencesInput,
  CoachSportPreferencesOutput,
  GetAthleteActivitiesInput,
  GetAthleteActivitiesOutput,
  GetCoachRecruitingNeedsInput,
  GetCoachRecruitingNeedsOutput,
  GetCoachSportListInput,
  GetCoachSportListOutput,
  InterestedSearchQueryInput,
  InterestedSearchQueryOutput,
  SearchAllCoachSportSavedSearchesQueryInput,
  SearchAllCoachSportSavedSearchesQueryOutput,
  SearchAthleticMeasurablesQueryOutput,
  SearchCoachByEmailInput,
  SearchCoachByEmailOutput,
  SearchCoachSportFiltersQueryInput,
  SearchCoachSportFiltersQueryOutput,
  SearchMajorsQueryOutput,
  SearchMeasurable,
  SearchPosition,
  SearchPositionsQueryOutput,
  SearchQueryInput,
  SearchQueryOutput,
  SearchSportsQueryOutput,
} from 'types/search.types';
import { Sport } from 'types/sport.types';

import { COACH_X_API_TAG_TYPES, coachxApi } from './coachxApi.api';
import { buildSortKey } from './search.service.functions';

const BASE_SEARCH_PATH = '/search';

export const searchApi = coachxApi.injectEndpoints({
  endpoints: build => ({
    getSearchAthletes: build.query<SearchQueryOutput, SearchQueryInput>({
      query: query => {
        const { sortKey, sortOrder, screen, ...rest } = query;
        const sortString = buildSortKey({ sortKey, sortOrder });

        const searchParams = new URLSearchParams({
          ...rest,
          sort: sortString,
        } as any).toString();
        return `${BASE_SEARCH_PATH}/athletes?${searchParams}`;
      },
      providesTags: (result, _error, { screen }) => [
        { type: COACH_X_API_TAG_TYPES.athletes, id: screen },
      ],
    }),
    getInterestedAthletes: build.query<
      InterestedSearchQueryOutput,
      InterestedSearchQueryInput
    >({
      query: query => {
        const { screen, ...rest } = query;
        const sortString = buildSortKey({
          defaultOptions: DEFAULT_INTERESTED_SORT(rest.isInterested),
        });

        const searchParams = new URLSearchParams({
          ...rest,
          sort: sortString,
        } as any).toString();
        return `${BASE_SEARCH_PATH}/athletes?${searchParams}`;
      },
      providesTags: (result, _error, { screen }) => [
        { type: COACH_X_API_TAG_TYPES.athletes, id: screen },
      ],
      serializeQueryArgs: ({ queryArgs }) => queryArgs.coachSport,
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.gradYear !== previousArg?.gradYear ||
        currentArg?.skip !== previousArg?.skip,
      transformResponse: (
        response: InterestedSearchQueryOutput,
        meta,
        { gradYear },
      ) => ({
        ...response,
        gradYear,
      }),
      // Always merge incoming data to the cache entry on scroll
      merge: (currentCache, newItems, { arg }) => {
        if (
          currentCache.total !== newItems.total ||
          currentCache.gradYear !== arg.gradYear
        ) {
          currentCache.results = newItems.results;
          currentCache.count = newItems.count;
          currentCache.total = newItems.total;
          currentCache.offset = newItems.offset;
          currentCache.gradYear = arg.gradYear;
        } else if (newItems.results) {
          currentCache.results = currentCache.results
            ? [...currentCache.results, ...newItems.results]
            : [...newItems.results];
        }
      },
    }),
    getCoachSportLists: build.query<
      GetCoachSportListOutput,
      GetCoachSportListInput
    >({
      query: ({ coachID, sportSlug }) => {
        return `${BASE_SEARCH_PATH}/lists/${coachID}/${sportSlug}`;
      },
      providesTags: () => [COACH_X_API_TAG_TYPES.lists],
      transformResponse: ({ result }) => {
        return result ?? [];
      },
    }),
    getAthlete: build.query<Athlete, AthleteQueryInput>({
      query: query => {
        const searchParams = new URLSearchParams({
          ...query,
          sort: 'client_id:desc',
        }).toString();
        return `${BASE_SEARCH_PATH}/athletes?${searchParams}`;
      },
      transformResponse: ({ results }) => {
        if (!isValidArray(results)) {
          return null;
        }
        return results[0];
      },
    }),
    getPositions: build.query<SearchPosition[], BaseSportInput>({
      query: ({ sportSlug }) => {
        return `${BASE_SEARCH_PATH}/positions/${sportSlug}`;
      },
      transformResponse: ({ result }: SearchPositionsQueryOutput) => {
        return result ?? [];
      },
    }),
    getMeasurables: build.query<SearchMeasurable[], BaseSportInput>({
      query: ({ sportSlug }) => {
        return `${BASE_SEARCH_PATH}/measurables/${sportSlug}`;
      },
      transformResponse: ({ result }: SearchAthleticMeasurablesQueryOutput) => {
        return result ?? [];
      },
    }),
    getMajors: build.query<MultiSelectComboboxItem[], null>({
      query: () => {
        return `${BASE_SEARCH_PATH}/majors`;
      },
      transformResponse: ({ result }: SearchMajorsQueryOutput) => {
        // Sorts cipCodes, stringifies the output of that sort, checks for matches during acc and builds a unique list of Majors associated to CipCodes
        const uniqueResults = result.reduce((acc, { cipCodes, name }) => {
          const cipCodesString = cipCodes
            .map(num => parseInt(num, 10))
            .sort((a, b) => a - b)
            .join('|');

          if (!acc.some(item => item.value === cipCodesString)) {
            acc.push({
              label: name,
              value: cipCodesString,
              abbreviation: name,
            });
          }
          return acc;
        }, [] as MultiSelectComboboxItem[]);

        return uniqueResults;
      },
    }),
    getSports: build.query<Sport[], string>({
      query: ecosystemCollegeReferenceID => {
        return `${BASE_SEARCH_PATH}/college/${ecosystemCollegeReferenceID}/programs`;
      },
      transformResponse: ({ result }: SearchSportsQueryOutput) => {
        return result;
      },
    }),
    getColleges: build.query<College[], null>({
      query: () => {
        return `${BASE_SEARCH_PATH}/colleges`;
      },
      transformResponse: ({ result }: { result: College[] }) => {
        return result;
      },
    }),
    getCoachByEmail: build.query<
      SearchCoachByEmailOutput,
      SearchCoachByEmailInput
    >({
      query: ({ email }) => {
        return `${BASE_SEARCH_PATH}/coach/email/${email}`;
      },
      providesTags: [COACH_X_API_TAG_TYPES.getCoach],
      async onQueryStarted(authCoach, { dispatch, queryFulfilled }) {
        try {
          const { data: validCoach } = await queryFulfilled;

          if (!validCoach) {
            dispatch(coachActions.setCoach(authCoach));
          } else {
            const { sports, ...coachSliceData } = validCoach;
            dispatch(coachActions.setCoach(coachSliceData));
          }
        } catch {
          dispatch(coachActions.setCoach(null));
        }
      },
    }),
    getAllCoachSportSavedSearches: build.query<
      SearchAllCoachSportSavedSearchesQueryOutput,
      SearchAllCoachSportSavedSearchesQueryInput
    >({
      query: ({ coachID, sportSlug }) => {
        return `${BASE_SEARCH_PATH}/saved-search/coach-sport/${coachID}/${sportSlug}`;
      },
      providesTags: [COACH_X_API_TAG_TYPES.savedSearches],
      transformResponse: ({ result }) => {
        return result ?? [];
      },
    }),
    getSearchFiltersBySport: build.query<ApiFilter[], BaseSportInput>({
      query: ({ sportSlug }) => {
        return `${BASE_SEARCH_PATH}/filters/${sportSlug}?screen=${SCREENS.search}`;
      },
      transformResponse: ({ results }) => {
        return results ?? [];
      },
    }),
    getSearchColumnsBySport: build.query<
      TableColumn<Athlete>[],
      BaseSportInput
    >({
      query: ({ sportSlug }) => {
        return `${BASE_SEARCH_PATH}/columns/search/${sportSlug}`;
      },
    }),
    getListColumnsBySport: build.query<TableColumn<Athlete>[], BaseSportInput>({
      query: ({ sportSlug }) => {
        return `${BASE_SEARCH_PATH}/columns/list/${sportSlug}`;
      },
    }),
    getListsFiltersBySport: build.query<ApiFilter[], BaseSportInput>({
      query: ({ sportSlug }) => {
        return `${BASE_SEARCH_PATH}/filters/${sportSlug}?screen=${SCREENS.lists}`;
      },
      transformResponse: ({ results }) => {
        return results ?? [];
      },
    }),
    getCoachSetupStepsValues: build.query<SetupStepsFormValues, any>({
      providesTags: [COACH_X_API_TAG_TYPES.setupStepsValues],
      async queryFn(
        { sportSlug, coachID, sportID },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        try {
          const [preferencesResponse, needsResponse] = await Promise.all([
            fetchWithBQ(
              `${BASE_SEARCH_PATH}/coach/${coachID}/sport/${sportSlug}/preferences`,
            ),
            fetchWithBQ(
              `${BASE_SEARCH_PATH}/coach/sport/${sportID}/recruiting-needs`,
            ),
          ]);

          const errors: FetchBaseQueryError[] = [
            preferencesResponse,
            needsResponse,
          ]
            .map(response => response.error)
            .filter(Boolean) as FetchBaseQueryError[];

          if (errors.length > 0) {
            return { error: errors[0] };
          }

          const {
            data: { results: preference },
          } = preferencesResponse as any;

          const {
            data: { results: needs },
          } = needsResponse as any;

          if (!preference || !needs) {
            return {
              data: null,
            };
          }

          const {
            id: preferenceID,
            states,
            minSAT,
            minACT,
            ...restPreferences
          } = preference;
          const {
            id: recruitingNeedID,
            deletedAt,
            positions,
            ...restNeeds
          } = needs[0];

          return {
            data: {
              preferenceID,
              recruitingNeedID,
              savedSearchName: '',
              states: states
                ? states.split(',')
                : SETUP_FORM_INITIAL_VALUES.states,
              positions: positions
                ? positions.split(',')
                : SETUP_FORM_INITIAL_VALUES.positions,
              ...restPreferences,
              ...restNeeds,
            },
          };
        } catch (error) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: getErrorMessage(error),
            },
          };
        }
      },
    }),
    getCoachSportSearchFilters: build.query<
      SearchCoachSportFiltersQueryOutput,
      SearchCoachSportFiltersQueryInput
    >({
      providesTags: [COACH_X_API_TAG_TYPES.coachSportSearchFilters],
      async queryFn(
        { sportSlug, savedSearchID },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        try {
          const isValidSearchId =
            savedSearchID && savedSearchID !== initialState.id;

          const [defaultSportFiltersResponse, defaultSportSavedSearchResponse] =
            await Promise.all([
              fetchWithBQ(
                `${BASE_SEARCH_PATH}/filters/${sportSlug}?screen=${SCREENS.search}`,
              ),
              isValidSearchId
                ? fetchWithBQ(
                    `${BASE_SEARCH_PATH}/saved-search/${savedSearchID}`,
                  )
                : Promise.resolve({ error: undefined, data: { result: null } }),
            ]);

          const errors: FetchBaseQueryError[] = [
            defaultSportFiltersResponse,
            defaultSportSavedSearchResponse,
          ]
            .map(response => response.error)
            .filter(Boolean) as FetchBaseQueryError[];

          if (errors.length > 0) {
            return { error: errors[0] };
          }

          const {
            data: { results: defaultSportFilters },
          } = defaultSportFiltersResponse as any;

          const {
            data: { result: defaultSavedSearch },
          } = defaultSportSavedSearchResponse as any;

          return {
            data: {
              defaultSportFilters,
              defaultSavedSearch,
            },
          };
        } catch (error) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: undefined,
              error: getErrorMessage(error),
            },
          };
        }
      },
    }),
    getAthleteActivities: build.query<
      GetAthleteActivitiesOutput,
      GetAthleteActivitiesInput
    >({
      providesTags: [COACH_X_API_TAG_TYPES.athleteActivities],
      async queryFn(
        { coachID, athleteID, sportSlug },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const fetchFollowedActivity = async (): Promise<ListAthleteData[]> => {
          try {
            const { data: followedListData } = (await fetchWithBQ(
              `${BASE_SEARCH_PATH}/athlete/followed/coach-sport/${coachID}/${sportSlug}`,
            )) as QueryReturnValue<{
              result: ListAthleteData[];
            }>;

            if (!followedListData || !isValidArray(followedListData.result)) {
              return [];
            }

            const listAthleteData = followedListData.result.find(
              a => a.athleteID === athleteID,
            );

            if (!listAthleteData) {
              return [];
            }

            return [listAthleteData];
          } catch (error) {
            console.error('Error fetching followed activities', error);
            return [];
          }
        };

        const fetchActivities = async <T>(url: string): Promise<T[]> => {
          try {
            const { data } = (await fetchWithBQ(url)) as any;
            if (!data || !isValidArray(data.result)) {
              return [];
            }
            return data.result;
          } catch (error) {
            console.error('Error fetching activities', error);
            return [];
          }
        };

        const notes = await fetchActivities<AthleteNote>(
          `${BASE_SEARCH_PATH}/note/${coachID}/${athleteID}`,
        );
        const emails = await fetchActivities<AthleteEmail>(
          `${BASE_SEARCH_PATH}/email/${coachID}/${athleteID}`,
        );
        const followed = await fetchFollowedActivity();

        const results: Activity[] = [
          ...prepareActivityPayload(notes, 'note'),
          ...prepareActivityPayload(emails, 'email'),
          ...prepareActivityPayload(followed, 'followed'),
        ];

        return {
          data: results,
        };
      },
    }),
    getAllLatestCoachAthleteNotes: build.query<
      BaseCoachAthleteActivity[],
      BaseCoachID
    >({
      query: ({ coachID }) => {
        return `${BASE_SEARCH_PATH}/note/athlete/latest/${coachID}`;
      },
      providesTags: [COACH_X_API_TAG_TYPES.notedAthletes],
      transformResponse: ({ result }) => {
        if (!result) return [];

        return result.map(({ athleteID, ...data }) => ({
          athleteID: Number(athleteID),
          ...data,
        }));
      },
    }),
    getAllLatestCoachAthleteEmails: build.query<
      BaseCoachAthleteActivity[],
      BaseCoachID
    >({
      query: ({ coachID }) => {
        return `${BASE_SEARCH_PATH}/email/athlete/latest/${coachID}`;
      },
      providesTags: [COACH_X_API_TAG_TYPES.emailedAthletes],
      transformResponse: ({ result }) => {
        if (!result) return [];

        return result.map(({ athleteID, ...data }) => ({
          athleteID: Number(athleteID),
          ...data,
        }));
      },
    }),
    getAllCoachHiddenAthleteIdsForSport: build.query<number[], BaseSearchInput>(
      {
        query: ({ coachID, sportSlug }) => {
          return `${BASE_SEARCH_PATH}/athlete/hidden/coach-sport/${coachID}/${sportSlug}`;
        },
        providesTags: [COACH_X_API_TAG_TYPES.hiddenAthleteIds],
        transformResponse: ({ result }: { result: ListAthleteData[] }) => {
          if (!result) return [];
          const ids = result.map(({ athleteID }) => athleteID);
          return convertStringsToNumbers(ids);
        },
      },
    ),
    getAllCoachFollowedAthleteIdsForSport: build.query<
      number[],
      BaseSearchInput
    >({
      query: ({ coachID, sportSlug }) => {
        return `${BASE_SEARCH_PATH}/athlete/followed/coach-sport/${coachID}/${sportSlug}`;
      },
      providesTags: [COACH_X_API_TAG_TYPES.followedAthleteIds],
      transformResponse: ({ result }: { result: ListAthleteData[] }) => {
        if (!result) return [];
        const ids = result.map(({ athleteID }) => athleteID);
        return convertStringsToNumbers(ids);
      },
    }),
    getAllCoachViewedAthleteIdsForSport: build.query<number[], BaseSearchInput>(
      {
        query: ({ coachID, sportSlug }) => {
          return `${BASE_SEARCH_PATH}/athlete/viewed/coach-sport/${coachID}/${sportSlug}`;
        },
        providesTags: [COACH_X_API_TAG_TYPES.viewedAthleteIds],
        transformResponse: ({ result }: { result: ListAthleteData[] }) => {
          if (!result) return [];
          const ids = result.map(({ athleteID }) => athleteID);
          return convertStringsToNumbers(ids);
        },
      },
    ),
    getCoachRecruitingNeeds: build.query<
      GetCoachRecruitingNeedsOutput,
      GetCoachRecruitingNeedsInput
    >({
      query: ({ coachSportID, limit, year }) => {
        return `${BASE_SEARCH_PATH}/coach/sport/${coachSportID}/recruiting-needs?since=${year}&limit=${limit}`;
      },
      providesTags: () => [COACH_X_API_TAG_TYPES.recruitingNeeds],
      transformResponse: ({ results }) => {
        return results ?? [];
      },
    }),
    getCoachSportPreferences: build.query<
      CoachSportPreferencesOutput,
      CoachSportPreferencesInput
    >({
      query: ({ coachID, sportSlug }) => {
        return `${BASE_SEARCH_PATH}/coach/${coachID}/sport/${sportSlug}/preferences`;
      },
      transformResponse: ({ results }) => {
        return results;
      },
      providesTags: () => [COACH_X_API_TAG_TYPES.sportPreferences],
    }),
    getCoachProfileImageURL: build.query<string, void>({
      query: () => {
        return `${BASE_SEARCH_PATH}/identity/avatar`;
      },
      transformResponse: ({ results }) => {
        return results;
      },
      providesTags: [COACH_X_API_TAG_TYPES.coachProfileImage],
    }),
  }),
});

export const {
  useGetInterestedAthletesQuery,
  useGetSearchAthletesQuery,
  useGetAthleteQuery,
  useGetMeasurablesQuery,
  useGetPositionsQuery,
  useGetMajorsQuery,
  useGetSportsQuery,
  useGetCollegesQuery,
  useGetCoachByEmailQuery,
  useGetAllCoachSportSavedSearchesQuery,
  useGetAllCoachHiddenAthleteIdsForSportQuery,
  useGetAllCoachFollowedAthleteIdsForSportQuery,
  useGetAllCoachViewedAthleteIdsForSportQuery,
  useGetSearchFiltersBySportQuery,
  useGetSearchColumnsBySportQuery,
  useGetCoachSportSearchFiltersQuery,
  useGetCoachRecruitingNeedsQuery,
  useGetCoachSportPreferencesQuery,
  useGetCoachSetupStepsValuesQuery,
  useGetListColumnsBySportQuery,
  useGetListsFiltersBySportQuery,
  useGetAthleteActivitiesQuery,
  useLazyGetSportsQuery,
  useGetCoachSportListsQuery,
  useGetAllLatestCoachAthleteEmailsQuery,
  useGetAllLatestCoachAthleteNotesQuery,
  useGetCoachProfileImageURLQuery,
} = searchApi;
