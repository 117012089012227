import { useCallback, useEffect, useState } from 'react';

import { TimeUnit } from 'types/idle.types';

import { convertToMilliseconds } from './convertToMilliseconds.function';

interface UseCheckForUpdatesOptions {
  interval?: number;
  unit?: TimeUnit;
}

const fetchVersion = async (): Promise<string> => {
  const response = await fetch('/version.json');
  const data = await response.json();
  return data.version;
};

export const useCheckForUpdates = ({
  interval = 5,
  unit = 'minute',
}: UseCheckForUpdatesOptions = {}) => {
  const [initialVersion, setInitialVersion] = useState<string>();
  const [isUpdated, setIsUpdated] = useState(false);

  const checkForUpdates = useCallback(async () => {
    try {
      const currentVersion = await fetchVersion();
      const storedVersion = localStorage.getItem('appVersion');

      if (!storedVersion || !initialVersion) {
        localStorage.setItem('appVersion', currentVersion);
        setInitialVersion(currentVersion);
        return;
      }

      if (storedVersion !== currentVersion) {
        setIsUpdated(true);
        localStorage.setItem('appVersion', currentVersion);
      }
    } catch (error) {
      console.error('Failed to check for updates:', error);
    }
  }, [initialVersion]);

  const handleRefresh = useCallback(async () => {
    await fetchVersion()
      .then(setInitialVersion)
      .finally(() => window.location.reload());
  }, []);

  useEffect(() => {
    checkForUpdates();
    const intervalInMs = convertToMilliseconds(interval, unit);
    const intervalId = setInterval(checkForUpdates, intervalInMs);

    return () => clearInterval(intervalId);
  }, [checkForUpdates, interval, unit]);

  return {
    handleRefresh,
    isUpdated,
  };
};
