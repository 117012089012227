import { useAppSelector } from 'hooks/useAppSelector';
import { Helmet } from 'react-helmet-async';
import { BUILD_MODE } from 'vite-constants';

import { selectIsCoachOnboarded } from 'store/slices/coach/coach.slice.selectors';

export const ExternalScripts = () => {
  const isOnboardedCoach = useAppSelector(selectIsCoachOnboarded);

  if (BUILD_MODE !== 'production' || !isOnboardedCoach) return null;

  return (
    <Helmet>
      <script src="/ketch.js" async />
      <script src="/mopinion.js" async />
      <script src="/hotjar.js" async />
    </Helmet>
  );
};
