import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { environment } from 'utils/runtime-environment/RuntimeEnvironment';

export const Auth0ProviderWithNavigate = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const domain = environment.env.auth0Domain;
  const clientId = environment.env.auth0ClientId;
  const audience = environment.env.auth0Audience;
  const redirectUri = `${window.location.origin}`;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  const signUpOrLogin = window.location.href.includes('/new-account-sign-up')
    ? 'signup'
    : 'login';

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
        screen_hint: signUpOrLogin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
