import { createSlice } from '@reduxjs/toolkit';
import { searchApi } from 'services/search.service';
import { storageApi } from 'services/storage.service';

import { searchFiltersActions } from 'store/slices/searchFilters/searchFilters.slice';

import { initialState } from './coachSavedSearch.constants';

const slice = createSlice({
  name: 'coachSavedSearch',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(searchFiltersActions.resetFilters, () => {
        return initialState;
      })
      .addMatcher(
        storageApi.endpoints.postSaveSearch.matchFulfilled,
        (_, { payload }) => {
          return payload;
        },
      )
      .addMatcher(
        storageApi.endpoints.putSavedSearch.matchFulfilled,
        (_, { payload }) => {
          return payload;
        },
      )
      .addMatcher(
        searchApi.endpoints.getCoachSportSearchFilters.matchFulfilled,
        (_, { payload: { defaultSavedSearch } }) => {
          return !defaultSavedSearch ? initialState : defaultSavedSearch;
        },
      );
  },
});

export const { actions: coachSavedSearchActions } = slice;

export const useCoachSavedSearchSlice = () => {
  return { actions: slice.actions };
};

export default slice.reducer;
