import { IdleConfig } from 'types/idle.types';

interface RefreshConfigurations {
  version: IdleConfig;
  inactive: IdleConfig;
}

export type RuntimeConfiguration = {
  auth0ClientId: string;
  auth0Domain: string;
  auth0Audience: string;
  coachxApiUrl: string;
  useJsonServer?: boolean;
  jsonServerUrl?: string;
  frontRushBaseUrl: string;
  refreshConfigurations: RefreshConfigurations;
};

class RuntimeEnvironment {
  private config: RuntimeConfiguration | undefined;

  set env(config) {
    if (!this.config) {
      this.config = config;
      Object.freeze(this.config);
      Object.freeze(this);
    } else {
      throw new Error('Environment Configuration has already been set');
    }
  }

  get env(): RuntimeConfiguration {
    if (!this.config) {
      throw new Error('Environment Configuration has not been set');
    } else {
      return this.config;
    }
  }
}

export const environment = new RuntimeEnvironment();
export const PUBLIC_ENVIRONMENT_URL = '/config/env.json';
