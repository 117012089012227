import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconBell = forwardRef<SVGSVGElement, IconProps>(
  (
    { stroke = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26 27"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M25.0308 21.7033C25.184 22.3717 25.2354 22.8898 25.2471 23.25H13.0196H12.9804H0.752865C0.764619 22.8898 0.815999 22.3717 0.969158 21.7033C1.31369 20.1999 2.17696 17.9208 4.2847 14.9602C5.13728 13.7626 5.33029 12.1495 5.4071 10.605C5.43192 10.106 5.44494 9.61138 5.45783 9.12166C5.48699 8.01349 5.51549 6.93059 5.67851 5.88015C5.90807 4.40094 6.38733 3.16281 7.40783 2.27788C8.43137 1.39032 10.1214 0.752892 13 0.75001C15.8786 0.752892 17.5686 1.39032 18.5922 2.27788C19.6127 3.16281 20.0919 4.40094 20.3215 5.88015C20.4845 6.93059 20.513 8.01349 20.5422 9.12165C20.5551 9.61138 20.5681 10.106 20.5929 10.605C20.6697 12.1495 20.8627 13.7626 21.7153 14.9602C23.823 17.9208 24.6863 20.1999 25.0308 21.7033Z"
          strokeWidth="1.5"
        />
        <path
          d="M15.5 23.5C15.5 24.8807 14.3807 26 13 26C11.6193 26 10.5 24.8807 10.5 23.5"
          strokeWidth="1.5"
        />
      </svg>
    );
  },
);
IconBell.displayName = 'IconBell';
