import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const ICON_VIDEO_TEST_ID = 'icon-video';

export const IconVideo = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        width={width ?? size}
        height={height ?? size}
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        role="graphics-symbol"
        data-testid={ICON_VIDEO_TEST_ID}
        {...props}
      >
        <path
          d="M13.999 5.49956C13.999 5.1945 13.999 4.88943 13.999 4.58437C13.999 3.6043 13.9952 2.62452 13.9874 1.64504C13.9847 1.35799 13.9029 1.07802 13.752 0.839417C13.4858 0.446688 13.1475 0.460716 12.8233 0.803477C11.9825 1.69062 11.1423 2.57835 10.3027 3.46667C10.2065 3.56748 10.1053 3.66303 9.91961 3.848C9.91961 3.19929 9.91961 2.65403 9.91961 2.10965C9.91961 0.930585 9.5183 0.510684 8.39149 0.509807C6.08561 0.509807 3.77808 0.528215 1.4722 0.500163C0.66461 0.49052 -0.0202702 0.908671 0.00045858 2.0404C0.0153833 2.98277 0.0211878 3.92602 0.0228461 4.86927C0.0228461 5.07966 0.0228461 5.28917 0.0228461 5.49956C0.0228461 5.70995 0.0228461 5.92034 0.0228461 6.13073C0.0228461 7.07398 0.0153833 8.01636 0.00045858 8.95961C-0.0177828 10.0913 0.663781 10.5095 1.4722 10.4998C3.77808 10.4718 6.08561 10.4902 8.39149 10.4893C9.5183 10.4893 9.91796 10.0694 9.91961 8.89035C9.91961 8.34509 9.91961 7.79983 9.91961 7.152C10.1053 7.33609 10.2065 7.43164 10.3027 7.53333C11.1434 8.42048 11.9837 9.30821 12.8233 10.1965C13.1475 10.5384 13.4866 10.5533 13.752 10.1597C13.903 9.92115 13.9848 9.64114 13.9874 9.35409C13.9941 8.37519 13.9979 7.3957 13.999 6.41563C14.0007 6.11057 13.9999 5.80463 13.999 5.49956Z"
          fill={fill}
        />
      </svg>
    );
  },
);
IconVideo.displayName = 'IconVideo';
