import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Engagement, EngagementState } from './engagement.slice.types';

export const initialState = null as EngagementState;

const slice = createSlice({
  name: 'engagement',
  initialState,
  reducers: {
    setEngagementHeaders: (
      state,
      {
        payload: { responseID, requestID, engagementID },
      }: PayloadAction<Engagement>,
    ) => {
      return {
        ...state,
        responseID,
        requestID,
        engagementID,
      };
    },
  },
});

export const { actions: engagementActions } = slice;

export const useEngagementSlice = () => {
  return { actions: slice.actions };
};

export default slice.reducer;
