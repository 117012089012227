import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconCaretDown = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      fill = 'currentColor',
      stroke = 'currentColor',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 5"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M4.375 4.375L0.625 0.625H8.125L4.375 4.375Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
IconCaretDown.displayName = 'IconCaretDown';
