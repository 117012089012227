import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconRadio = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width={width ?? size}
        height={height ?? size}
        fill={fill}
        stroke={stroke}
        {...props}
        ref={forwardedRef}
      >
        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" />
        <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" />
      </svg>
    );
  },
);
IconRadio.displayName = 'IconRadio';
