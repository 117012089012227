import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconThumbsUp = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 21"
        width={width ?? size}
        height={height ?? size}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <path
          id="icon"
          d="M7.16667 5.5L7.16667 1L9.16667 1C10.2712 1 11.1667 1.89543 11.1667 3V7.16667V7.66667H11.6667H17.5C18.6046 7.66667 19.5 8.5621 19.5 9.66667V13.7153L16.3576 20L5.5 20L5.5 8.95137L7.11388 5.72361L7.16667 5.61803V5.5ZM2.83333 9.33333L2.83333 20H0.5L0.5 9.33333H2.83333Z"
          stroke={stroke}
        />
      </svg>
    );
  },
);
IconThumbsUp.displayName = 'IconThumbsUp';
