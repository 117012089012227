import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconAscenderFull = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 972 306"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <path
          fill={fill}
          d="M164.52,236.43l-0.14,0.05L134.08,48.33l-5.51,138.71l-2.26,62.62l-0.66-6.51l-11.19-107.91L101.51,10.38
	L88.6,135.23L77.44,243.14l-0.7,6.51l-2.22-62.62L68.11,49.08L38.55,235.99l2.54-156.08L9.83,237.95l91.68,47.79l91.73-47.75
	L161.95,79.91L164.52,236.43z M496.83,290.07c-6.37,0-10.8-1.95-13.72-6.07c-2.62-3.75-3.98-8.62-3.98-14.4
	c0-13.2,6.38-20.55,17.85-20.55c5.25,0,9.67,1.8,12.15,5.03c1.65,2.17,2.4,4.2,2.78,8.25h6.22v-17.77h-5.85v6.83
	c-2.92-5.25-8.25-7.88-16.27-7.88c-15.22,0-24.22,9.6-24.22,25.87c0,16.65,9.07,26.25,24.97,26.25c12.97,0,20.92-6.6,21.52-17.92
	h-7.05C510.11,285.95,505.38,290.07,496.83,290.07z M371.19,75.5h17.17c0,40.57,0.5,48.53,4.73,97.56h-0.99
	c-8.21-24.39-12.44-35.09-20.66-52.27l-36.08-73.41h-80.14V75.5h16.43v109.75h-16.43v28.12h72.17v-28.12h-17.17
	c-0.74-50.77-0.99-58.49-4.47-103.53h0.99c7.46,23.15,11.45,33.6,18.91,48.53l42.56,83.12h58.74V75.5h16.43V47.38h-72.18V75.5z
	 M601.15,185.75c4.98-8.71,7.21-17.42,7.71-29.36h-46.79c-0.99,19.66-12.2,31.36-30.37,31.36c-24.38,0-37.82-19.67-37.82-55.5
	c0-38.32,13.44-59.23,38.07-59.23c19.91,0,31.85,12.94,32.61,35.84h43.05V47.37h-39.58v18.17c-3.73-6.22-5.97-8.71-9.94-11.94
	c-8.72-6.72-22.4-10.45-37.59-10.45c-26.13,0-45.78,8.71-58.98,26.13c-11.45,15.18-17.42,35.59-17.42,59.73
	c0,29.11,8.46,52.51,24.39,66.94c15.43,14.19,37.58,21.66,63.71,21.66C564.07,217.61,589.95,205.66,601.15,185.75z M723.17,116.31
	l-29.37-10.2c-18.91-6.72-22.4-9.46-22.4-17.67c0-9.95,8.46-15.43,23.64-15.43c19.66,0,28.13,8.21,28.13,26.87h38.07V47.37h-38.07
	v14.93c-6.97-13.19-19.41-19.16-41.07-19.16c-36.58,0-57.73,18.67-57.73,51.27c0,21.65,10.7,35.34,34.34,44.05l37.08,13.68
	c17.67,6.48,21.16,9.46,21.16,18.17c0,10.7-9.71,17.42-25.64,17.42c-18.42,0-26.38-9.46-26.88-31.36h-40.81v56.99h38.08v-15.93
	c7.46,14.18,19.91,20.16,42.8,20.16c39.07,0,60.73-18.68,60.73-52.27C765.23,139.95,754.28,127.51,723.17,116.31z M723.48,249.5
	h7.72v17.25h-26.25V249.5h7.72v-4.95h-22.12v4.95h7.72v40.12h-7.72v4.95h22.12v-4.95h-7.72v-17.85h26.25v17.85h-7.72v4.95h22.12
	v-4.95h-7.72V249.5h7.72v-4.95h-22.12V249.5z M952.22,185.24L897.46,47.37h-56.49l-55.25,137.87h-10.2v28.12h71.43v-28.12h-18.67
	l7.21-19.41h59.24l7.22,19.41h-18.67v28.12h78.89v-28.12H952.22z M846.94,138.46l18.17-54.75l18.17,54.75H846.94z M611.06,244.55
	h-7.95l-19.12,45.07h-5.62v4.95h19.95v-4.95h-7.57l4.72-11.4h22.65l4.72,11.4h-7.42v4.95h20.32v-4.95h-5.62L611.06,244.55z
	 M597.56,273.2l9.22-22.8l9.22,22.8H597.56z M550.76,243.5c-7.2,0-13.05,2.03-17.77,6.15c-4.95,4.42-7.58,11.1-7.58,19.35
	c0,8.62,1.95,14.4,6.45,19.2c4.65,4.88,11.17,7.42,19.12,7.42c9,0,16.35-3.38,20.85-9.52c3.08-4.27,4.58-9.82,4.58-16.72
	c0-8.02-1.95-13.57-6.22-18.22C565.31,245.98,559.01,243.5,550.76,243.5z M551.06,290.07c-6,0-11.47-2.62-14.55-6.9
	c-2.4-3.45-3.75-8.47-3.75-14.17c0-4.8,1.2-9.67,3.15-12.75c2.92-4.58,8.4-7.2,14.92-7.2c6.38,0,12.08,2.7,15,7.12
	c2.1,3.15,3.22,7.65,3.22,12.9C569.06,282.05,562.23,290.07,551.06,290.07z M662.73,290.07c-6.38,0-10.8-1.95-13.72-6.07
	c-2.62-3.75-3.97-8.62-3.97-14.4c0-13.2,6.37-20.55,17.85-20.55c5.25,0,9.67,1.8,12.15,5.03c1.65,2.17,2.4,4.2,2.78,8.25h6.22
	v-17.77h-5.85v6.83c-2.92-5.25-8.25-7.88-16.27-7.88c-15.22,0-24.22,9.6-24.22,25.87c0,16.65,9.07,26.25,24.97,26.25
	c12.97,0,20.92-6.6,21.52-17.92h-7.05C676.01,285.95,671.28,290.07,662.73,290.07z"
        />
      </svg>
    );
  },
);
