import { ERROR_MESSAGE } from 'app/utils/message.constants';

import { Flex } from '@mantine/core';

import { Button } from 'app/components/Button';
import { StatusMessage } from 'app/components/StatusMessage';
import { StatusMessageDetails } from 'app/components/StatusMessage/StatusMessageDetails';

import { MAILTO_LINK } from 'utils/customerSupport.constants';

import './CoachProviderError.component.scss';

export const ERROR_DESCRIPTION =
  'NCSA Coach has experienced a problem. You can try to refresh this page or log out.';

export const CoachProviderError = ({ details }: { details: string }) => {
  return (
    <Flex
      className="coach-provider-error"
      direction="column"
      align="center"
      justify="center"
      gap={50}
    >
      <StatusMessage
        title={ERROR_MESSAGE}
        description={ERROR_DESCRIPTION}
        action={<StatusMessageDetails details={details} />}
        showResponseMetaOutput
      />

      <Button
        onClick={() => {
          window.location.assign(MAILTO_LINK);
        }}
        size="large"
      >
        Contact Support
      </Button>
    </Flex>
  );
};
