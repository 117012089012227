export const CONFIGURABLE_FILTER_TYPES = {
  boolean: 'boolean',
  multiselect: 'multiselect',
  range: 'range',
  select: 'select',
  rangeSelect: 'rangeSelect',
} as const;

export const DEFAULT_FILTERS = {
  gradYear: 'gradYear',
  majors: 'majors',
  positions: 'positions',
  rating: 'rating',
  states: 'states',
  act: 'act',
  sat: 'sat',
  minGPA: 'minGPA',
  hasVideo: 'hasVideo',
  isInterested: 'isInterested',
  isNew: 'isNew',
  isRecentlyActive: 'isRecentlyActive',
  isOpenToCoachCollegeState: 'isOpenToCoachCollegeState',
  isOpenToChristianSchools: 'isOpenToChristianSchools',
  isOpenToDivisionSchools: 'isOpenToDivisionSchools',
  includeCommitted: 'includeCommitted',
  includeHidden: 'includeHidden',
  excludeInactive: 'excludeInactive',
  excludeWithEmails: 'excludeWithEmails',
  excludeWithNotes: 'excludeWithNotes',
} as const;
