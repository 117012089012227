/**
 *
 * HeaderContainer Component
 *
 */
import './HeaderContainer.component.scss';

interface HeaderContainerProps {
  children: React.ReactNode;
}

export const HeaderContainer = ({ children }: HeaderContainerProps) => {
  return (
    <header role="banner" className="main-header">
      {children}
    </header>
  );
};
