import classNames from 'classnames';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

import {
  ActionIconProps,
  Flex,
  ActionIcon as MantineActionIcon,
} from '@mantine/core';

import { MetaText } from '../MetaText';

export type ActionIconVariants = 'primary' | 'secondary' | 'tertiary';
export type ActionIconColors = 'error';

type HTMLButtonProps = Omit<
  ComponentPropsWithoutRef<'button'>,
  'color' | 'style'
>;

export interface CommonActionIconProps
  extends Omit<ActionIconProps, 'size'>,
    HTMLButtonProps {
  variant?: ActionIconVariants;
  color?: ActionIconColors;
  displayLabel?: string;
}

export const ActionIcon = forwardRef<HTMLButtonElement, CommonActionIconProps>(
  (
    { variant = 'primary', color, className, disabled, displayLabel, ...props },
    ref,
  ) => {
    const classList = classNames(className, {
      disabled,
      [`color-${color}`]: color,
    });

    if (displayLabel) {
      return (
        <Flex
          className="action-icon__container"
          direction="column"
          align="center"
          justify="center"
        >
          <MantineActionIcon
            {...props}
            ref={ref}
            variant={variant}
            className={classList}
            disabled={disabled}
          />
          <MetaText className="action-icon__label">{displayLabel}</MetaText>
        </Flex>
      );
    }

    return (
      <MantineActionIcon
        {...props}
        ref={ref}
        variant={variant}
        className={classList}
        disabled={disabled}
      />
    );
  },
);
ActionIcon.displayName = 'ActionIcon';

export interface SelectionButtonProps
  extends Omit<CommonActionIconProps, 'color'> {
  selected?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const SelectionActionIcon = forwardRef<
  HTMLButtonElement,
  SelectionButtonProps
>(({ selected = false, className, ...props }, ref) => {
  const classList = classNames(className, 'color-selection', {
    active: selected,
  });

  return <ActionIcon {...props} ref={ref} className={classList} />;
});
SelectionActionIcon.displayName = 'SelectionActionIcon';
