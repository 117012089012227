import { useOnWindowResize } from 'hooks/useOnWindowResize';
import { useCallback } from 'react';

import { Menu, Modal, Radio, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { SportSelectorButton } from '../SportSelectorButton/SportSelectorButton.component';
import './SportSelectorModal.component.scss';

import { useSportSelector } from '../useSportSelector';

export const SportSelectorModal = () => {
  const {
    sports,
    selectedSportSlug,
    selectedSportName,
    isSportSelectorVisible,
    handleUpdateSelectedSport,
    isDesktop,
  } = useSportSelector();

  const [isOpen, { toggle, close }] = useDisclosure(false);
  useOnWindowResize(useCallback(() => close(), [close]));

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    toggle();
  };

  if (!isSportSelectorVisible || isDesktop) {
    return null;
  }

  return (
    <>
      <SportSelectorButton
        selectedSportName={selectedSportName}
        onClick={handleClick}
      />
      <Modal
        centered
        opened={isOpen}
        onClose={toggle}
        className="sport-selector-modal"
        title="Sport"
      >
        <Radio.Group
          name="selectSport"
          value={selectedSportSlug}
          onChange={handleUpdateSelectedSport}
        >
          <Stack>
            {sports.map(({ slug, sportName }) => {
              return <Radio key={slug} value={slug} label={sportName} />;
            })}
          </Stack>
        </Radio.Group>
      </Modal>
    </>
  );
};

export const SportSelectorMenuItem = () => {
  const { isSportSelectorVisible, isDesktop } = useSportSelector();

  if (!isSportSelectorVisible || isDesktop) {
    return null;
  }

  return (
    <Menu.Item component="div">
      <SportSelectorModal />
    </Menu.Item>
  );
};
