import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const ICON_LAST_ACTIVE_TEST_ID = 'icon-last-active';

export const IconLastActive = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', stroke = '#424242', size = 12, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        role="graphics-symbol"
        data-testid={ICON_LAST_ACTIVE_TEST_ID}
        {...props}
      >
        <path
          id="Vector 50"
          d="M1 7.54398L5.12828 0.5H8.48688L6.01458 4.7218H10.1429L3.91545 12.5L5.12828 7.54398H1Z"
          fill={fill}
          stroke={stroke}
        />
      </svg>
    );
  },
);
IconLastActive.displayName = 'IconLastActive';
