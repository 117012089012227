import deepmerge from 'deepmerge';

import { FilterPanelFilters } from './filtersState.types';

/*
 * NOTE: Using this custom overwrite function to make sure array data that we are getting
 * from saved search values (ex: rating: [2.6]) replace our default values but not add to those values.
 * Read more here: https://www.npmjs.com/package/deepmerge
 */

const overwriteMerge = (_: any, sourceArray: any) => sourceArray;

export const deepMergeMappedFiltersToSearchFilters = (
  panelFilters: FilterPanelFilters,
  filters: Partial<FilterPanelFilters>,
) => {
  return deepmerge(panelFilters, filters, { arrayMerge: overwriteMerge });
};
