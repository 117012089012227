import {
  ApiFilter,
  ApiFilterAdditionalConfig,
  BaseFilterConfig,
  ConfigurableFilterTypes,
  DynamicDataConfig,
  FilterData,
  IndividualFilterConfig,
} from 'app/components/Filters/filters.types';

import { FilterPanelFilters } from './filtersState.types';

interface BaseFilterData
  extends BaseFilterConfig,
    Partial<DynamicDataConfig>,
    FilterData<any> {
  component: ConfigurableFilterTypes;
}

const getAddons = (baseFilterData: BaseFilterData, apiFilter: ApiFilter) => {
  const { componentType, additionalConfig } = apiFilter;
  if (!additionalConfig) {
    return baseFilterData as IndividualFilterConfig;
  }

  const prepareAdditionalConfig = (
    component: ConfigurableFilterTypes,
    additionalConfig: ApiFilterAdditionalConfig,
  ) => {
    switch (component) {
      case 'range':
      case 'rangeSelect': {
        return Object.entries(additionalConfig).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: value,
          };
        }, {});
      }
      default:
        return additionalConfig;
    }
  };

  const addons = prepareAdditionalConfig(componentType, additionalConfig);

  return {
    ...baseFilterData,
    ...addons,
  } as IndividualFilterConfig;
};

const getBaseData = (filter: ApiFilter) => {
  const {
    name,
    data,
    label,
    value,
    dynamic,
    placeholder,
    componentType: component,
    tooltip,
  } = filter;

  const base = {
    name,
    label,
    value,
    component,
    tooltip,
  };
  switch (component) {
    case 'boolean':
    case 'range': {
      return base;
    }
    default: {
      return {
        ...base,
        data,
        dynamic,
        placeholder,
      };
    }
  }
};

export const mapDefaultSportFiltersToState = (
  filters: ApiFilter[],
): FilterPanelFilters => {
  return filters.reduce((acc: FilterPanelFilters, apiFilter: ApiFilter) => {
    const { name } = apiFilter;

    const base: BaseFilterData = getBaseData(apiFilter);
    const filter = getAddons(base, apiFilter);

    return {
      ...acc,
      [name]: filter,
    };
  }, {});
};
