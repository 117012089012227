/**
 *
 * HeaderMenu Component
 *
 */
import { useAuth0 } from '@auth0/auth0-react';
import { LOG_OUT } from 'app/utils/general.constants';
import { useAppSelector } from 'hooks/useAppSelector';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { Flex, Menu, UnstyledButton } from '@mantine/core';

import { CoachAvatar } from 'app/components/CoachAvatar';
import { SportSelectorMenuItem } from 'app/components/SportSelector';

import {
  selectIsCoachOnboarding,
  selectIsCoachVerified,
} from 'store/slices/coach/coach.slice.selectors';

import { Coach } from 'types/coach.types';

import './HeaderMenu.component.scss';

type ExtendedNavLinkProps = NavLinkProps & {
  label: string;
};

export const MENU_NAV_LINKS: ExtendedNavLinkProps[] = [
  { to: '/settings/profile', label: 'My Profile' },
  { to: '/settings/searches', label: 'Saved Searches' },
  { to: '/settings/preferences', label: 'Preferences' },
  { to: '/settings/needs', label: 'Recruiting Needs' },
  {
    to: `mailto:feedback@ncsasports.org?subject=Feedback on NCSA Coach`,
    label: 'Send Feedback',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
];

export const HeaderMenu = () => {
  const { logout } = useAuth0();
  const coach = useAppSelector(state => state.coach) as Coach;
  const isCoachVerified = useAppSelector(selectIsCoachVerified);
  const isCoachOnboarding = useAppSelector(selectIsCoachOnboarding);
  const showAllMenuOptions = isCoachVerified && !isCoachOnboarding;

  const avatarSize = 30;

  const buildMenuItems = () => {
    return (
      <>
        {MENU_NAV_LINKS.map(({ to, label, target, rel }) => {
          return (
            <Menu.Item key={label} component="div" aria-label={label}>
              <NavLink to={to} target={target} rel={rel}>
                {label}
              </NavLink>
            </Menu.Item>
          );
        })}
      </>
    );
  };

  return (
    <Flex align="center" className="main-header-menu">
      <Menu
        position="bottom-end"
        classNames={{
          dropdown: 'main-header-dropdown',
          item: 'main-header-dropdown__item',
          divider: 'main-header-dropdown__divider',
        }}
      >
        <Menu.Target>
          <UnstyledButton
            className="main-header-menu__button"
            aria-labelledby="main-header-menu__button-label"
          >
            <span id="main-header-menu__button-label" hidden>
              Toggle Menu
            </span>
            <CoachAvatar
              className="main-header-menu__avatar"
              alt="User Avatar"
              variant="outline"
              size={avatarSize}
              coach={coach}
              loadingOverlayProps={{
                overlayProps: { backgroundOpacity: 0 },
                loaderProps: { color: 'white', size: avatarSize },
              }}
            />
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          <SportSelectorMenuItem />
          {showAllMenuOptions ? buildMenuItems() : null}
          <Menu.Divider />
          <Menu.Item
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            {LOG_OUT}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Flex>
  );
};
