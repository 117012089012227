import { forwardRef } from 'react';

import { IconProps } from 'app/components/Icons/icons.component.types';

export const IconTwitter = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 16, width, height, fill, ...props }, forwardRef) => {
    return (
      <svg
        width={width ?? size}
        height={height ?? size}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
        ref={forwardRef}
      >
        <path
          d="M12.4997 24.987C19.3961 24.987 24.9868 19.3964 24.9868 12.5C24.9868 5.60357 19.3961 0.0129395 12.4997 0.0129395C5.60333 0.0129395 0.0126953 5.60357 0.0126953 12.5C0.0126953 19.3964 5.60333 24.987 12.4997 24.987Z"
          fill={fill}
        />
        <path
          d="M4.80812 5.26538L10.7768 13.2459L4.77051 19.7344H6.12239L11.381 14.0535L15.6296 19.7344H20.2298L13.9252 11.3051L19.5159 5.26538H18.164L13.3212 10.4973L9.40829 5.26538H4.80812ZM6.79612 6.26106H8.90942L18.2415 18.7388H16.1282L6.79612 6.26106Z"
          fill="white"
        />
      </svg>
    );
  },
);
IconTwitter.displayName = 'IconTwitter';
