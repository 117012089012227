import { PropsWithChildren, StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import { configuredStore } from 'store/configureStore';

import { theme } from 'utils/_globalThemeConfiguration';
import { DEFAULT_TOAST_TIMEOUT } from 'utils/notifications';

import { Auth0ProviderWithNavigate } from './Auth0ProviderWithNavigate';
import { AuthProvider } from './AuthProvider';
import { CoachProvider } from './CoachProvider';

export const BaseProviders = ({ children }: PropsWithChildren) => {
  return (
    <MantineProvider theme={theme}>
      <HelmetProvider>
        <StrictMode>
          <BrowserRouter>{children}</BrowserRouter>
        </StrictMode>
      </HelmetProvider>
      <Notifications
        position="bottom-right"
        zIndex={9999}
        autoClose={DEFAULT_TOAST_TIMEOUT}
      />
    </MantineProvider>
  );
};

export const BaseWithAuthProviders = ({ children }: PropsWithChildren) => {
  return (
    <BaseProviders>
      <Auth0ProviderWithNavigate>
        <AuthProvider>
          <CoachProvider>{children}</CoachProvider>
        </AuthProvider>
      </Auth0ProviderWithNavigate>
    </BaseProviders>
  );
};

export const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <Provider store={configuredStore}>
      <BaseWithAuthProviders>{children}</BaseWithAuthProviders>
    </Provider>
  );
};
