import { NotificationStatus } from './notifications.types';

export const DEFAULT_TOAST_TIMEOUT = 3000;
export const NOTIFICATION_STATUS_MAP = new Map<NotificationStatus, string>([
  ['success', 'var(--coachx-semantic-success-100)'],
  ['warn', 'var(--coachx-semantic-warning-100)'],
  ['error', 'var(--coachx-semantic-error-90)'],
]);

export const ERROR_TOAST_MESSAGE = 'Sorry, something went wrong!';
