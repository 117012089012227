import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconCopy = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        viewBox="0 0 17 17"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <g id="Group 4835">
          <path
            id="Rectangle 8888"
            d="M5.5 13.8H1V1H13.5217V4.5"
            stroke={stroke}
          />
          <rect
            id="Rectangle 8889"
            x="5.67188"
            y="4.7002"
            width="10.8261"
            height="11.8"
            stroke={stroke}
          />
        </g>
      </svg>
    );
  },
);
IconCopy.displayName = 'IconCopy';
