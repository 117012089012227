/**
 *
 * Header Component
 *
 */
import { useAppSelector } from 'hooks/useAppSelector';
import { useAuth0EmailVerified } from 'hooks/useAuth0EmailVerified';

import { SportSelectorMenu } from 'app/components/SportSelector';

import {
  selectIsCoachOnboarding,
  selectIsCoachValid,
  selectIsCoachVerified,
} from 'store/slices/coach/coach.slice.selectors';

import { HeaderActions } from './HeaderActions';
import { HeaderContainer } from './HeaderContainer';
import { HeaderDrawer } from './HeaderDrawer';
import { HeaderLogo } from './HeaderLogo';
import { HeaderMenu } from './HeaderMenu';
import { HeaderNav } from './HeaderNav';

export const Header = () => {
  const isCoachValid = useAppSelector(selectIsCoachValid);
  const isCoachVerified = useAppSelector(selectIsCoachVerified);
  const isCoachOnboarding = useAppSelector(selectIsCoachOnboarding);
  const isCoachEmailVerified = useAuth0EmailVerified();

  if (!isCoachValid || !isCoachEmailVerified) {
    return null;
  }

  const showNavigation = isCoachVerified && !isCoachOnboarding;

  return (
    <HeaderContainer>
      {showNavigation ? (
        <>
          <HeaderDrawer />
          <HeaderLogo />
          <HeaderNav />
        </>
      ) : (
        <HeaderLogo />
      )}
      <HeaderActions>
        {isCoachVerified ? <SportSelectorMenu /> : null}
        <HeaderMenu />
      </HeaderActions>
    </HeaderContainer>
  );
};
