export type TimeUnit = 'millisecond' | 'second' | 'minute' | 'hour';

export interface IdleConfig {
  interval: number;
  unit: TimeUnit;
}

export const DEFAULT_IDLE_TIMEOUT_CONFIGURATION: IdleConfig = {
  interval: 15,
  unit: 'minute',
} as const;
