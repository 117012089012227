import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useSearchParams } from 'react-router-dom';

import { useMediaQuery } from '@mantine/hooks';

import { SEARCH_PARAMS } from 'app/components/Router/Router.constants';

import { useCoachSportsSlice } from 'store/slices/coachSports/coachSports.slice';
import {
  selectCoachSelectedSport,
  selectCoachSports,
} from 'store/slices/coachSports/coachSports.slice.selectors';

import { Breakpoints } from 'utils/breakpoints';

export const useSportSelector = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { actions } = useCoachSportsSlice();
  const dispatch = useAppDispatch();
  const coachSports = useAppSelector(selectCoachSports);
  const coachSelectedSport = useAppSelector(selectCoachSelectedSport);
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.tablet})`);
  const isSportSelectorVisible = coachSports.length > 1;

  const handleUpdateSelectedSport = (slug: string) => {
    if (coachSelectedSport.slug === slug) return;
    searchParams.delete(SEARCH_PARAMS.savedSearch);
    setSearchParams(searchParams);

    const sport = coachSports.find(sport => sport.slug === slug)!;
    dispatch(actions.setCoachSelectedSport({ sport }));
  };

  return {
    sports: coachSports,
    selectedSportSlug: coachSelectedSport.slug,
    selectedSportName: coachSelectedSport.sportName,
    isSportSelectorVisible,
    handleUpdateSelectedSport,
    isDesktop,
  };
};
