/**
 *
 * AuthMiddleware Component
 * Responsible for automatic login user in case access token is present
 *
 */
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { auth } from 'services/auth';

import { notifications } from '@mantine/notifications';

import { LoadingOverlay } from 'app/components/LoadingOverlay';

import { getColorForStatus } from 'utils/notifications';

interface AuthProviderProps {
  children: React.ReactElement;
}

const AUTH0_INVALID_STATE_ERROR_MESSAGE = 'Invalid state';
const INVALID_STATE_TOAST_MESSAGE = 'Refreshing app data';

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    error,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  auth.setAccessTokenSilently(getAccessTokenSilently);

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    const fn = async () => {
      await loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    };
    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (error) {
    console.log({ error });
    const isInvalidState = error.message === AUTH0_INVALID_STATE_ERROR_MESSAGE;
    const notification = isInvalidState
      ? {
          message: INVALID_STATE_TOAST_MESSAGE,
          color: getColorForStatus('warn'),
        }
      : { message: error.message, color: getColorForStatus('error') };

    notifications.show(notification);
    return null;
  }

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return isAuthenticated ? children : null;
};
