import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootState.type';

import { initialState } from './engagement.slice';

const selectSlice = (state: RootState) => state.engagement || initialState;

export const selectEngagement = createSelector([selectSlice], state => state);

export const selectResponseID = createSelector(
  [selectSlice],
  state => state?.responseID,
);

export const selectEngagementID = createSelector(
  [selectSlice],
  state => state?.engagementID,
);
