import {
  RumActionEvent,
  RumErrorEvent,
  RumEvent,
  RumResourceEvent,
  RumViewEvent,
  datadogRum,
} from '@datadog/browser-rum';

const DATADOG_RUM_CLIENT_TOKEN = 'pubb4e081a6531c601957e9047a278c4c43';
const DATADOG_RUM_APPLICATION_ID = '927e255d-3937-43a6-8fa1-8c6938e927a0';

const setupDataDogRUM = () => {
  // credit-card-number-regex-start
  // This regex comes from: https://www.regular-expressions.info/creditcard.html
  const creditCardNumberRegex =
    /.*(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11}).*/gim;
  const creditCardNumberReplacement = '[CREDIT CARD NUMBER REDACTED]';
  // credit-card-number-regex-end

  // email-regex-start
  // This regex comes from: https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression#answer-201378
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const emailReplacement = '[EMAIL REDACTED]';
  // email-regex-end

  // phone-number-regex-start
  // This regex comes from: https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number#answer-16699507
  const phoneNumberRegex =
    /.*(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}.*/gim;
  const phoneNumberReplacement = '[PHONE NUMBER REDACTED]';
  // phone-number-regex-end

  // address-regex-start
  // This regex was created by looking at keywords we display in our front-end and from: https://docs.datadoghq.com/agent/faq/commonly-used-log-processing-rules/#postal-codes
  const addressRegex =
    /.*(address|country|state|street|zip|postal code|postal-code)|(?:\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d).*/gim;
  const addressReplacement = '[ADDRESS REDACTED]';
  // address-regex-end

  // personal-name-regex-start
  // This regex was determined by what might proceed or tail an actual name in a string.
  const personalNameRegex =
    /.*(first name|firstname|first_name|first-name|last name|lastname|last_name|last-name).*/gim;
  const personalNameReplacement = '[PERSONAL NAME REDACTED]';
  // personal-name-regex-end

  // social-security-regex-start
  // This regex comes from: https://docs.datadoghq.com/agent/faq/commonly-used-log-processing-rules/#social-security-numbers
  const socialSecurityNumberRegex = /.*(?:\d{3}-?\d{2}-?\d{4}).*/gim;
  const socialSecurityNumberReplacement = '[SOCIAL SECURITY NUMBER REDACTED]';
  // social-security-regex-end

  const clean = (dirty: any) => {
    return dirty
      .replace(creditCardNumberRegex, creditCardNumberReplacement)
      .replace(emailRegex, emailReplacement)
      .replace(phoneNumberRegex, phoneNumberReplacement)
      .replace(addressRegex, addressReplacement)
      .replace(personalNameRegex, personalNameReplacement)
      .replace(socialSecurityNumberRegex, socialSecurityNumberReplacement);
  };

  datadogRum.init({
    // https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
    applicationId: DATADOG_RUM_APPLICATION_ID,
    clientToken: DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'coachx-ui',
    env: 'ncsaprod',
    sessionSampleRate: 100,
    trackUserInteractions: true, // https://docs.datadoghq.com/real_user_monitoring/browser/tracking_user_actions/?tab=cdnsync
    allowedTracingUrls: [
      /^(https:\/\/)coachxapi\.prod\.ncsasports\.org(\/.*)?$/,
    ], // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces?tab=browserrum
    beforeSend: (event: RumEvent) => {
      // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=cdnsync#modify-the-content-of-a-rum-event

      if (isResourceEvent(event)) {
        event.resource.url = clean(event.resource.url);
      }

      // View Sanitization
      if (isViewEvent(event)) {
        event.view.url = clean(event.view.url);

        if (event.view.referrer) {
          event.view.referrer = clean(event.view.referrer);
        }
      }

      // Action Sanitization
      if (isActionEvent(event)) {
        if (event.action.target) {
          event.action.target.name = clean(event.action.target.name);
        }
      }

      // Error Sanitization
      if (isErrorEvent(event)) {
        event.error.message = clean(event.error.message);

        if (event.error.resource) {
          event.error.resource.url = clean(event.error.resource.url);
        }

        if (event.error.stack) {
          event.error.stack = clean(event.error.stack);
        }
      }
      return true;
    },
  });
};

const isResourceEvent = (event: any): event is RumResourceEvent =>
  !!event.resource;
const isViewEvent = (event: any): event is RumViewEvent => !!event.view;
const isActionEvent = (event: any): event is RumActionEvent => !!event.action;
const isErrorEvent = (event: any): event is RumErrorEvent => !!event.error;

export { setupDataDogRUM };
