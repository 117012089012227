import { GetTokenSilentlyOptions } from '@auth0/auth0-react';

/*
  https://github.com/reduxjs/redux-toolkit/issues/1331#issuecomment-1080901326

  TO avoid a race-condition between the getAccessTokenSilently request and the auth0-sdk context for isAuthenticated
  we are instead applying to token globally to the headers before any requests run.

  This implementation can be found under dynamicBaseQuery where we access the token and under AuthProvider.component where we set the token
*/
let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined,
) => Promise<string>;

export const auth = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  ) => {
    getAccessTokenSilently = func;
  },
};
