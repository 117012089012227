import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconThumbsDown = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 21"
        width={width ?? size}
        height={height ?? size}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <path
          id="icon"
          d="M13.3333 15.5L13.3333 20L11.3333 20C10.2288 20 9.33333 19.1046 9.33333 18L9.33333 13.8333L9.33333 13.3333L8.83333 13.3333L3 13.3333C1.89543 13.3333 1 12.4379 1 11.3333L1 7.2847L4.14235 0.999999L15 1L15 12.0486L13.3861 15.2764L13.3333 15.382L13.3333 15.5ZM17.6667 11.6667L17.6667 1L20 1L20 11.6667L17.6667 11.6667Z"
          stroke={stroke}
        />
      </svg>
    );
  },
);
IconThumbsDown.displayName = 'IconThumbsDown';
