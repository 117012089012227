import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconTrophy = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'currentColor',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 34 37"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <g id="Group 3516">
          <rect
            id="Rectangle 8359"
            x="28.0586"
            y="6"
            width="5.28502"
            height="13"
            fill={fill}
          />
          <rect
            id="Rectangle 8361"
            width="5.28502"
            height="13"
            transform="matrix(-1 0 0 1 5.86328 6)"
            fill={fill}
          />
          <rect
            id="Rectangle 8360"
            x="28.0586"
            y="8"
            width="3.17101"
            height="9"
            fill="white"
          />
          <rect
            id="Rectangle 8362"
            width="3.17101"
            height="9"
            transform="matrix(-1 0 0 1 5.86328 8)"
            fill="white"
          />
          <g id="Group 3499">
            <g id="Group 3498">
              <path
                id="Vector 31"
                d="M27.716 1H6.25V17.2026C6.25 30.8128 27.716 31.4609 27.716 17.2026V1Z"
                fill={fill}
                stroke={stroke}
              />
              <g id="Group 3497">
                <path
                  id="Rectangle 8348"
                  d="M15.6445 26.3828H18.3278V36.537H15.6445V34.6331V26.3828Z"
                  fill={fill}
                />
                <path
                  id="Rectangle 8349"
                  d="M26.375 34L26.375 36.5385L7.59228 36.5385L7.59228 34L11.114 34L26.375 34Z"
                  fill={fill}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);
IconTrophy.displayName = 'IconTrophy';
