/**
 * Create the store with dynamic reducers
 */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { coachxApi } from 'services/coachxApi.api';
import { BUILD_MODE } from 'vite-constants';

import { RootState } from './rootState.type';
import coachReducer from './slices/coach/coach.slice';
import coachSavedSearchReducer from './slices/coachSavedSearch/coachSavedSearch.slice';
import coachSportsReducer from './slices/coachSports/coachSports.slice';
import engagementReducer from './slices/engagement/engagement.slice';
import inspectedAthleteReducer from './slices/inspectedAthlete/inspectedAthlete.slice';
import interestedSearchReducer from './slices/interestedSearch/interestedSearch.slice';
import listDataTableReducer from './slices/listDataTable/listDataTable.slice';
import listFiltersReducer from './slices/listFilters/listFilters.slice';
import searchDataTableReducer from './slices/searchDataTable/searchDataTable.slice';
import searchFiltersReducer from './slices/searchFilters/searchFilters.slice';

export const rootReducer = combineReducers({
  [coachxApi.reducerPath]: coachxApi.reducer,
  coach: coachReducer,
  coachSports: coachSportsReducer,
  coachSavedSearch: coachSavedSearchReducer,
  searchDataTable: searchDataTableReducer,
  searchFilters: searchFiltersReducer,
  inspectedAthlete: inspectedAthleteReducer,
  listDataTable: listDataTableReducer,
  listFilters: listFiltersReducer,
  interestedSearch: interestedSearchReducer,
  engagement: engagementReducer,
});

export function configureAppStore(preloadedState?: Partial<RootState>) {
  const middlewares = [coachxApi.middleware];

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(...middlewares),
    devTools: BUILD_MODE !== 'production',
  });

  return store;
}

export const configuredStore = configureAppStore();

// enable listener behavior for the store
setupListeners(configuredStore.dispatch);

export type AppStore = ReturnType<typeof configureAppStore>;
export type AppDispatch = typeof configuredStore.dispatch;
