import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconAngleLeft = forwardRef<SVGSVGElement, IconProps>(
  (
    { stroke = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8 14"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M7 1L1 7L7 13"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
IconAngleLeft.displayName = 'IconAngleLeft';
