import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconCheckboxSelected = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" />
        <path
          d="M6 11.0526L9.62471 14L14 6"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="svg-inner-icon"
        />
      </svg>
    );
  },
);
IconCheckboxSelected.displayName = 'IconCheckboxSelected';
