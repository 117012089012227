import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconNote = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        width={width ?? size}
        height={height ?? size}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <path
          id="Rectangle 8832"
          d="M8.93381 16.4368L7.08166 15.3001L12.5913 4.59869L14.8879 2.87779L14.4471 5.7285L8.93381 16.4368Z"
          stroke={stroke}
        />
        <path
          id="Vector"
          d="M1.06641 3.40674H10.129M1.06641 8.70416H7.41023M1.06641 14.0016H4.69145"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
IconNote.displayName = 'IconNote';
