import { useAppSelector } from 'hooks/useAppSelector';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Navigate, RouteObject, useNavigate } from 'react-router-dom';

import { AthleteProfile } from 'app/pages/AthleteProfile';
import { EmailVerification } from 'app/pages/EmailVerification';
import { InterestedPage } from 'app/pages/Interested';
import { Lists } from 'app/pages/Lists';
import { SearchPage } from 'app/pages/Search';
import { Settings } from 'app/pages/Settings';
import { CoachNeeds } from 'app/pages/Settings/pages/CoachNeeds';
import { CoachProfile } from 'app/pages/Settings/pages/CoachProfile';
import { CoachSportPreferences } from 'app/pages/Settings/pages/CoachSportPreferences';
import { SavedSearches } from 'app/pages/Settings/pages/SavedSearches';
import { SetupSteps } from 'app/pages/SetupSteps';
import { SignUpPage } from 'app/pages/SignUp';
import { VerificationPage } from 'app/pages/Verification';
import { VerificationDeniedPage } from 'app/pages/VerificationDenied';

import { selectIsCoachOnboarding } from 'store/slices/coach/coach.slice.selectors';

const ProtectedOnboardingRoute = ({ children }: PropsWithChildren) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const isCoachOnboarding = useAppSelector(selectIsCoachOnboarding);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isCoachOnboarding) {
      setShouldRedirect(true);
    }
  }, [isCoachOnboarding]);

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/');
    }
  }, [shouldRedirect, navigate]);

  return children;
};

export const unverifiedRoutes: RouteObject[] = [
  {
    path: 'signup',
    element: <SignUpPage />,
  },
  {
    path: 'verification',
    element: <VerificationPage />,
  },
  {
    path: 'denied',
    element: <VerificationDeniedPage />,
  },
  {
    path: 'email-verification',
    element: <EmailVerification />,
  },
  {
    path: '*',
    element: <Navigate replace to="/signup" />,
  },
];

export const verifiedRoutes: RouteObject[] = [
  {
    path: '/',
    element: <InterestedPage />,
  },
  {
    path: 'search',
    element: <SearchPage />,
  },
  {
    path: 'lists',
    element: <Lists />,
  },
  {
    path: 'athlete/:athleteID',
    element: <AthleteProfile />,
  },
  {
    path: 'setup',
    element: (
      <ProtectedOnboardingRoute>
        <SetupSteps />
      </ProtectedOnboardingRoute>
    ),
  },
  {
    path: 'settings/*',
    element: <Settings />,
    children: [
      {
        element: <Navigate replace to="profile" />,
        index: true,
      },
      {
        path: 'profile',
        element: <CoachProfile />,
      },
      {
        path: 'preferences',
        element: <CoachSportPreferences />,
      },
      {
        path: 'searches',
        element: <SavedSearches />,
      },
      {
        path: 'needs',
        element: <CoachNeeds />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate replace to="/" />,
  },
];
