import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { InspectedAthleteState } from './inspectedAthlete.slice.types';

export const initialState = null as InspectedAthleteState;

const slice = createSlice({
  name: 'inspectedAthlete',
  initialState,
  reducers: {
    setInspected: (
      _,
      { payload: athlete }: PayloadAction<InspectedAthleteState>,
    ) => {
      return athlete;
    },
    resetInspected: () => initialState,
  },
});

export const { actions: inspectedAthleteActions } = slice;

export const useInspectedAthleteSlice = () => {
  return { actions: slice.actions };
};

export default slice.reducer;
