import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const ICON_PAPER_TEST_ID = 'icon-paper';

export const IconPaper = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 14"
        width={width ?? size}
        height={height ?? size}
        fill={fill}
        {...props}
        ref={forwardedRef}
        data-testid={ICON_PAPER_TEST_ID}
      >
        <rect
          x="1.8714"
          y="1"
          width="9.4"
          height="12"
          fill={fill}
          stroke={stroke}
        />
        <path d="M3.71075 3.875H8.43075" stroke={stroke} />
        <path d="M3.71075 6H9.43075" stroke={stroke} />
        <path d="M3.71075 8.125H7.43075" stroke={stroke} />
        <path d="M3.71075 10.125H9.43075" stroke={stroke} />
      </svg>
    );
  },
);
IconPaper.displayName = 'IconPaper';
