import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconSearchCircle = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = '#CECAC8',
      fill = '#7A7775',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        viewBox="0 0 80 80"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <circle
          cx="40"
          cy="40"
          r="35"
          fill={fill}
          stroke={stroke}
          strokeWidth="10"
        />
        <circle
          cx="45.0647"
          cy="35.9735"
          r="10.039"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M24.228 55.3971C23.8374 55.7876 23.8374 56.4208 24.228 56.8113C24.6185 57.2018 25.2516 57.2018 25.6422 56.8113L24.228 55.3971ZM37.215 42.4101L24.228 55.3971L25.6422 56.8113L38.6292 43.8243L37.215 42.4101Z"
          fill="white"
        />
      </svg>
    );
  },
);
IconSearchCircle.displayName = 'IconSearchCircle';
