/**
 * NOTE: More about structuring api please check here
 * https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#injecting-endpoints
 * Example: https://github.com/reduxjs/redux-toolkit/blob/master/examples/query/react/kitchen-sink/src/app/services/api.ts
 */
import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicApiBaseQuery } from './dynamicBaseQuery';

export const COACH_X_API_TAG_TYPES = {
  getCoach: 'getCoach',
  savedSearches: 'savedSearches',
  coachSportSearchFilters: 'coachSportSearchFilters',
  hiddenAthleteIds: 'hiddenAthleteIds',
  followedAthleteIds: 'followedAthleteIds',
  viewedAthleteIds: 'viewedAthleteIds',
  recruitingNeeds: 'recruitingNeeds',
  sportPreferences: 'sportPreferences',
  setupStepsValues: 'setupStepsValues',
  athletes: 'athletes',
  lists: 'lists',
  athleteActivities: 'athleteActivities',
  emailedAthletes: 'emailedAthletes',
  notedAthletes: 'notedAthletes',
  coachProfileImage: 'coachProfileImage',
} as const;

export const coachxApi = createApi({
  baseQuery: dynamicApiBaseQuery,
  tagTypes: [
    COACH_X_API_TAG_TYPES.getCoach,
    COACH_X_API_TAG_TYPES.savedSearches,
    COACH_X_API_TAG_TYPES.coachSportSearchFilters,
    COACH_X_API_TAG_TYPES.hiddenAthleteIds,
    COACH_X_API_TAG_TYPES.followedAthleteIds,
    COACH_X_API_TAG_TYPES.viewedAthleteIds,
    COACH_X_API_TAG_TYPES.recruitingNeeds,
    COACH_X_API_TAG_TYPES.sportPreferences,
    COACH_X_API_TAG_TYPES.setupStepsValues,
    COACH_X_API_TAG_TYPES.athletes,
    COACH_X_API_TAG_TYPES.lists,
    COACH_X_API_TAG_TYPES.athleteActivities,
    COACH_X_API_TAG_TYPES.emailedAthletes,
    COACH_X_API_TAG_TYPES.notedAthletes,
    COACH_X_API_TAG_TYPES.coachProfileImage,
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});
