import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { searchApi } from 'services/search.service';

import { createFilterPanelConfigurations } from '../shared/createFilterPanelConfigurations.function';
import { FiltersState } from '../shared/filtersState.types';
import { mapDefaultSportFiltersToState } from '../shared/mapDefaultSportFiltersToState.function';

export const initialState = {
  default: {},
  current: {},
  configuration: {},
} as FiltersState;

const slice = createSlice({
  name: 'listFilters',
  initialState,
  reducers: {
    setFilterValue(
      state,
      action: PayloadAction<{
        name: string;
        value: any;
      }>,
    ) {
      const { name, value } = action.payload;
      if (state.current[name]) {
        state.current[name]!.value = value;
      }
    },
    resetFilters(state) {
      state.current = state.default;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      searchApi.endpoints.getListsFiltersBySport.matchFulfilled,
      (state: FiltersState, { payload }: PayloadAction<any>) => {
        const mappedDefaultSportFilters =
          mapDefaultSportFiltersToState(payload);

        const filterPanelConfiguration =
          createFilterPanelConfigurations(payload);

        state.current = mappedDefaultSportFilters;
        state.default = mappedDefaultSportFilters;
        state.configuration = filterPanelConfiguration;
      },
    );
  },
});

export const { actions: listFiltersActions } = slice;

export const useListFiltersSlice = () => {
  return { actions: slice.actions };
};

export default slice.reducer;
