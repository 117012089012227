import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { auth } from 'services/auth';

import { RootState } from 'store/rootState.type';
import { CoachNoSports } from 'store/slices/coach/coach.slice.types';
import { engagementActions } from 'store/slices/engagement/engagement.slice';

import { JSON_SERVER } from 'utils/json-server.constants';
import { environment } from 'utils/runtime-environment/RuntimeEnvironment';

const ALLOWED_HEADERS = {
  authorization: 'authorization',
  coachUserID: 'cx-coach-id',
  requestID: 'request-id',
  responseID: 'response-id',
  engagementID: 'engagement-id',
} as const;

export const dynamicApiBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object,
) => {
  let latestResponseHeaders: Headers | null = null;

  const isUsingJsonServerForApiEndpoint =
    environment.env.useJsonServer &&
    JSON_SERVER.endpoints.includes(api.endpoint);

  if (isUsingJsonServerForApiEndpoint) {
    // Route this specific query to the JSON server
    const baseUrl = environment.env.jsonServerUrl;
    const baseQuery = fetchBaseQuery({ baseUrl });
    const result = await baseQuery(args, api, extraOptions);
    latestResponseHeaders = result.meta?.response?.headers || null;

    return result;
  }

  const baseUrl = environment.env.coachxApiUrl;
  const prepareHeaders = async (
    headers: Headers,
    { getState }: Pick<BaseQueryApi, 'getState'>,
  ) => {
    const token = await auth.getAccessTokenSilently()();
    const state = getState() as RootState;
    const coachId = (state.coach as CoachNoSports)?.id;
    const requestID = state.engagement?.requestID;

    if (token) {
      headers.set(ALLOWED_HEADERS.authorization, `Bearer ${token}`);
    }

    if (coachId) {
      headers.set(ALLOWED_HEADERS.coachUserID, coachId);
    }

    if (requestID) {
      headers.set(ALLOWED_HEADERS.requestID, requestID);
    }

    return headers;
  };

  const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });
  const result = await baseQuery(args, api, extraOptions);
  latestResponseHeaders = result.meta?.response?.headers || null;

  if (latestResponseHeaders) {
    const responseID = latestResponseHeaders.get(ALLOWED_HEADERS.requestID);
    const requestID = latestResponseHeaders.get(ALLOWED_HEADERS.responseID);
    const engagementID = latestResponseHeaders.get(
      ALLOWED_HEADERS.engagementID,
    );

    if (responseID || requestID) {
      api.dispatch(
        engagementActions.setEngagementHeaders({
          responseID: responseID || '',
          requestID: requestID || '',
          engagementID: engagementID || '',
        }),
      );
    }
  }

  return result;
};
