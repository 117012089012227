/**
 *
 * CoachMiddleware Component
 *
 */
import { useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import { useGetCoachByEmailQuery } from 'services/search.service';

import { LoadingOverlay } from 'app/components/LoadingOverlay';

import { Auth0User } from 'types/auth0.types';

import { CoachProviderError } from './CoachProviderError';

export const CoachProvider = ({ children }: PropsWithChildren) => {
  const { user, logout } = useAuth0<Auth0User>();
  const { isLoading, isError, error } = useGetCoachByEmailQuery({
    email: user!.email,
  });

  if (isError) {
    if (error && 'status' in error && error.status === 401) {
      logout({ logoutParams: { returnTo: window.location.origin } });
      return null;
    }

    return <CoachProviderError details={JSON.stringify(error)} />;
  }

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return children;
};
