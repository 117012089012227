// adapted from https://stackoverflow.com/a/57527009
export const disableIosTextFieldZoom = () => {
  const viewport = document.querySelector('meta[name=viewport]');

  if (viewport !== null) {
    let content = viewport.getAttribute('content');
    const regex = /maximum-scale=[0-9.]+/g;

    if (typeof content === 'string' && regex.test(content)) {
      content = content.replace(regex, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    viewport.setAttribute('content', content);
  }
};

export const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent);
