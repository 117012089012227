/**
 * index.tsx
 *
 * This is the entry file for the application and any providers/wrappers should
 * be configured here or externally and imported here.
 */
import AppWithProviders from 'app';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { setupDataDogRUM } from 'setupDataDog';
import { BUILD_MODE } from 'vite-constants';

import {
  disableIosTextFieldZoom,
  isIOS,
} from 'utils/prevent-ios-auto-magnification';
import {
  PUBLIC_ENVIRONMENT_URL,
  RuntimeConfiguration,
  environment,
} from 'utils/runtime-environment/RuntimeEnvironment';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const renderApplication = (config: RuntimeConfiguration) => {
  environment.env = config;

  createRoot(MOUNT_NODE!).render(<AppWithProviders />);

  // fix for iOS auto magnify zoom on input fields
  if (isIOS()) {
    disableIosTextFieldZoom();
  }

  if (BUILD_MODE !== 'development') {
    setupDataDogRUM();
  }
};

const renderConfigLoadFailure = error => {
  console.error(`error loading ${PUBLIC_ENVIRONMENT_URL}`, error);
  createRoot(MOUNT_NODE!).render(
    <p>Error loading application configuration</p>,
  );
};

fetch(PUBLIC_ENVIRONMENT_URL)
  .then(response => {
    return response
      .json()
      .then(renderApplication)
      .catch(renderConfigLoadFailure);
  })
  .catch(renderConfigLoadFailure);
