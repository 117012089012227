import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const ICON_USER_TEST_ID = 'icon-user';

export const IconUser = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      fill = '#ababaa',
      stroke = '#7a7775',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        fill="none"
        ref={forwardedRef}
        width={width ?? size}
        height={height ?? size}
        {...props}
        data-testid={ICON_USER_TEST_ID}
      >
        <circle cx="50" cy="50" r="50" fill="white" />
        <mask
          id="mask0_7017_70647"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={height}
        >
          <circle cx="50" cy="50" r="49.5" fill="#CECAC8" stroke="black" />
        </mask>
        <g mask="url(#mask0_7017_70647)">
          <path
            d="M0.0303758 129.97C0.0303758 85.4961 0.0371259 41.0226 0 -3.45084C0 -4.71312 0.283507 -5 1.54916 -5C34.3246 -4.9595 67.0967 -4.9595 99.8721 -5C101.026 -5 101.256 -4.77387 101.252 -3.61959C101.215 40.459 101.215 84.541 101.252 128.62C101.252 129.774 101.026 130.003 99.8721 130C66.5904 129.963 33.3121 129.97 0.0303758 129.97ZM50.4575 98.9357C57.2583 98.9357 64.0557 98.9357 70.8565 98.9357C75.5209 98.9357 80.1852 98.9289 84.8496 98.9424C85.798 98.9458 86.8679 98.9087 86.9691 97.7814C87.31 93.9709 87.2155 90.228 84.2758 87.2275C82.3149 85.2261 79.9422 83.8761 77.421 82.7353C73.4317 80.933 69.2027 79.7011 65.3349 77.6254C62.8002 76.2653 60.6165 74.5676 59.8841 71.5671C59.7018 70.8246 59.6951 70.1969 60.2486 69.5387C64.373 64.6685 66.8266 58.968 68.0248 52.7713C69.007 47.6952 69.52 42.5853 67.559 37.5159C64.9602 30.7928 58.1156 26.0676 50.6229 26.0676C43.1302 26.0676 36.2585 30.7793 33.6935 37.5159C32.205 41.4277 32.232 45.39 32.7181 49.4502C33.5922 56.7505 36.0931 63.3792 40.6765 69.1472C41.4561 70.1294 41.6283 70.9461 41.2199 72.0464C40.9431 72.7889 40.6428 73.5078 40.1804 74.1491C38.3882 76.6365 35.7016 77.8245 33.0286 78.9957C29.4341 80.5719 25.6743 81.7633 22.154 83.5183C16.8248 86.1678 13.5611 90.0626 14.1618 96.4415C14.3711 98.6691 14.4521 98.9357 16.737 98.9357C27.9794 98.9357 39.2185 98.9357 50.4575 98.9357Z"
            fill="#F2F0F0"
          />
          <path
            d="M50.4575 98.9364C39.2185 98.9364 27.9794 98.9364 16.7404 98.9364C14.4555 98.9364 14.3779 98.6698 14.1652 96.4422C13.5611 90.0667 16.8282 86.1719 22.1574 83.519C25.681 81.7674 29.4375 80.5726 33.0319 78.9964C35.705 77.8253 38.3916 76.6339 40.1837 74.1498C40.6461 73.5085 40.9499 72.7896 41.2233 72.0471C41.6317 70.9468 41.4629 70.1301 40.6799 69.1479C36.0931 63.3799 33.5922 56.7512 32.7214 49.4509C32.2354 45.3907 32.2084 41.4284 33.6968 37.5166C36.2619 30.7766 43.1336 26.0684 50.6263 26.0684C58.1189 26.0684 64.9636 30.7901 67.5624 37.5166C69.52 42.586 69.007 47.6959 68.0282 52.772C66.83 58.9653 64.3763 64.6658 60.252 69.5394C59.6951 70.1976 59.7052 70.822 59.8875 71.5679C60.6199 74.5683 62.8036 76.266 65.3382 77.6261C69.2061 79.7052 73.4384 80.9371 77.4244 82.736C79.949 83.8768 82.3217 85.2268 84.2792 87.2283C87.2189 90.2287 87.3134 93.9751 86.9725 97.7821C86.8713 98.9094 85.8014 98.9465 84.853 98.9432C80.1886 98.9297 75.5242 98.9364 70.8599 98.9364C64.0557 98.9364 57.2583 98.9364 50.4575 98.9364Z"
            fill={fill}
          />
        </g>
        <circle cx="50" cy="50" r="49.5" stroke={stroke} />
      </svg>
    );
  },
);
IconUser.displayName = 'IconUser';
