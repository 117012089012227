import { TimeUnit } from 'types/idle.types';

export const convertToMilliseconds = (
  interval: number,
  unit: TimeUnit,
): number => {
  switch (unit) {
    case 'second':
      return interval * 1000;
    case 'minute':
      return interval * 60 * 1000;
    case 'hour':
      return interval * 60 * 60 * 1000;
    case 'millisecond':
    default:
      return interval;
  }
};
