import { App } from './App.component';
import { AppProviders } from './providers/AppProviders.component';

import { MaintenancePortal } from './portals';

export const AppWithProviders = () => {
  return (
    <AppProviders>
      <App />
      <MaintenancePortal />
    </AppProviders>
  );
};
