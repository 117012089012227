import {
  AthleteEmail,
  AthleteNote,
  CollegeInterest,
} from 'types/athlete.types';

import { ListAthleteData } from './list.types';

export const ACTIVITIES = {
  note: 'note',
  email: 'email',
  followed: 'followed',
  interest: 'interest',
} as const;

export type Activities = keyof typeof ACTIVITIES;

interface ActivityType {
  type: Activities;
}
interface BaseActivity {
  createdAt: string;
}

export type NoteActivity = AthleteNote & ActivityType;
export type EmailActivity = AthleteEmail & ActivityType;
export type FollowedActivity = ListAthleteData & ActivityType;
export type InterestActivity = Omit<CollegeInterest, 'interestedAt'> &
  BaseActivity &
  ActivityType;

export type Activity =
  | NoteActivity
  | EmailActivity
  | InterestActivity
  | FollowedActivity;

export const isNoteActivity = (
  activity: Activity,
): activity is NoteActivity => {
  return activity.type === 'note';
};

export const isEmailActivity = (
  activity: Activity,
): activity is EmailActivity => {
  return activity.type === 'email';
};

export const isFollowedActivity = (
  activity: Activity,
): activity is FollowedActivity => {
  return activity.type === 'followed';
};

export const isInterestActivity = (
  activity: Activity,
): activity is InterestActivity => {
  return activity.type === 'interest';
};

const addActivityType = (type: Activities) => (item: any) => ({
  type,
  ...item,
});

export const prepareActivityPayload = (payload: any[], type: Activities) =>
  payload.map(addActivityType(type));
