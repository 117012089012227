import { SCREENS } from 'app/utils/screen.constants';

import { coachActions } from 'store/slices/coach/coach.slice';

import { BaseID } from 'types/base.types';
import { CoachSavedSearch } from 'types/coachSavedSearch.types';
import {
  DeleteSavedSearchInput,
  SavedSearchInput,
  UpdateSavedSearchInput,
} from 'types/search.types';
import {
  BaseApiOutput,
  DeleteAthleteNoteInput,
  DeleteFollowedAthleteInput,
  DeleteHiddenAthleteInput,
  DeleteRecruitingNeedInput,
  PatchAthleteNoteInput,
  PatchCoachOnboardOutput,
  PatchPreferenceInput,
  PatchRecruitingNeedInput,
  PostAthleteAddToFrontRushInput,
  PostAthleteNoteInput,
  PostAthleteProfileViewInput,
  PostCoachInput,
  PostCoachOutput,
  PostCoachSportPreferencesInput,
  PostEmailInput,
  PostFollowedAthleteInput,
  PostHiddenAthleteInput,
  PostRecruitingNeedInput,
  PostResendVerificationEmailInput,
  PutCoachInput,
  PutCoachOutput,
} from 'types/storage.types';

import { COACH_X_API_TAG_TYPES, coachxApi } from './coachxApi.api';
import { searchApi } from './search.service';

const BASE_STORAGE_PATH = '/storage';
export const storageApi = coachxApi.injectEndpoints({
  endpoints: build => ({
    postCoach: build.mutation<PostCoachOutput, PostCoachInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/coach`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { sports, ...coachSliceData } = data;
          dispatch(coachActions.setCoach(coachSliceData));
        } catch {
          dispatch(coachActions.setCoach(null));
        }
      },
    }),
    putCoach: build.mutation<PutCoachOutput, PutCoachInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/coach`,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
      invalidatesTags: [COACH_X_API_TAG_TYPES.getCoach],
    }),
    patchCoachOnboard: build.mutation<PatchCoachOnboardOutput, BaseID>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/coach/onboard`,
        method: 'PATCH',
        body: JSON.stringify(data),
      }),
    }),
    postEmail: build.mutation<BaseApiOutput, PostEmailInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/email`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: [
        COACH_X_API_TAG_TYPES.athleteActivities,
        COACH_X_API_TAG_TYPES.emailedAthletes,
      ],
    }),
    postSaveSearch: build.mutation<CoachSavedSearch, SavedSearchInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/saved-search`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: [COACH_X_API_TAG_TYPES.savedSearches],
    }),
    postPreference: build.mutation<BaseID, PostCoachSportPreferencesInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/preference`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: [COACH_X_API_TAG_TYPES.sportPreferences],
    }),
    patchPreference: build.mutation<BaseID, PatchPreferenceInput>({
      query: ({ id, ...formData }) => ({
        url: `${BASE_STORAGE_PATH}/preference/${id}`,
        method: 'PATCH',
        body: JSON.stringify(formData),
      }),
      invalidatesTags: () => {
        return [COACH_X_API_TAG_TYPES.sportPreferences];
      },
    }),
    putSavedSearch: build.mutation<CoachSavedSearch, UpdateSavedSearchInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/saved-search`,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
      invalidatesTags: updatedSavedSearch => {
        if (updatedSavedSearch?.default) {
          return [
            COACH_X_API_TAG_TYPES.savedSearches,
            COACH_X_API_TAG_TYPES.coachSportSearchFilters,
          ];
        }
        return [COACH_X_API_TAG_TYPES.savedSearches];
      },
    }),
    deleteSavedSearch: build.mutation<BaseApiOutput, DeleteSavedSearchInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/saved-search/${data.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { default: isDefault }) => {
        if (isDefault) {
          return [
            COACH_X_API_TAG_TYPES.savedSearches,
            COACH_X_API_TAG_TYPES.coachSportSearchFilters,
          ];
        }
        return [COACH_X_API_TAG_TYPES.savedSearches];
      },
    }),
    deleteRecruitingNeed: build.mutation<
      BaseApiOutput,
      DeleteRecruitingNeedInput
    >({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/recruiting-need/${data.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [COACH_X_API_TAG_TYPES.recruitingNeeds],
    }),
    patchRecruitingNeed: build.mutation<
      BaseApiOutput,
      PatchRecruitingNeedInput
    >({
      query: ({ id, ...rest }) => ({
        url: `${BASE_STORAGE_PATH}/recruiting-need/${id}`,
        method: 'PATCH',
        body: JSON.stringify(rest),
      }),
      invalidatesTags: [COACH_X_API_TAG_TYPES.recruitingNeeds],
    }),
    postRecruitingNeeds: build.mutation<BaseApiOutput, PostRecruitingNeedInput>(
      {
        query: ({ coachSportID, ...rest }) => ({
          url: `${BASE_STORAGE_PATH}/coach/sport/${coachSportID}/recruiting-need`,
          method: 'POST',
          body: JSON.stringify(rest),
        }),
        invalidatesTags: [
          COACH_X_API_TAG_TYPES.recruitingNeeds,
          COACH_X_API_TAG_TYPES.setupStepsValues,
        ],
      },
    ),
    postAthleteAddToFrontRush: build.mutation<
      BaseApiOutput,
      PostAthleteAddToFrontRushInput
    >({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/athlete/frontrush`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
    }),
    deleteAthleteNote: build.mutation<BaseApiOutput, DeleteAthleteNoteInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/note`,
        method: 'DELETE',
        body: JSON.stringify(data),
      }),
      invalidatesTags: [
        COACH_X_API_TAG_TYPES.athleteActivities,
        COACH_X_API_TAG_TYPES.notedAthletes,
      ],
    }),
    postAthleteNote: build.mutation<BaseApiOutput, PostAthleteNoteInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/note`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: [
        COACH_X_API_TAG_TYPES.athleteActivities,
        COACH_X_API_TAG_TYPES.notedAthletes,
      ],
    }),
    patchAthleteNote: build.mutation<BaseApiOutput, PatchAthleteNoteInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/note`,
        method: 'PATCH',
        body: JSON.stringify(data),
      }),
      invalidatesTags: [COACH_X_API_TAG_TYPES.athleteActivities],
    }),
    postHiddenAthlete: build.mutation<BaseApiOutput, PostHiddenAthleteInput>({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/athlete/hidden`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: (result, _error, { isIncludeHidden, isHiddenList }) => {
        const commonTags = [
          COACH_X_API_TAG_TYPES.hiddenAthleteIds,
          COACH_X_API_TAG_TYPES.lists,
        ];

        // Mostly used when the include hidden toggle is active on the Search screen to only invalidate hidden athlete ID's instead of the entire cache of athletes
        // For the hidden list screen, we still need similar behavior, but we do need to poperly remove cache for athletes
        if (isIncludeHidden && !isHiddenList) {
          return commonTags;
        }

        return [...commonTags, COACH_X_API_TAG_TYPES.athletes];
      },
      async onQueryStarted(
        { coachID, sportSlug, athleteID },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          searchApi.util.updateQueryData(
            'getAllCoachHiddenAthleteIdsForSport',
            { coachID, sportSlug },
            draft => {
              draft.push(Number(athleteID));
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteHiddenAthlete: build.mutation<
      BaseApiOutput,
      DeleteHiddenAthleteInput
    >({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/athlete/hidden`,
        method: 'DELETE',
        body: JSON.stringify(data),
      }),
      invalidatesTags: (result, _error, { isIncludeHidden, isHiddenList }) => {
        const commonTags = [
          COACH_X_API_TAG_TYPES.hiddenAthleteIds,
          COACH_X_API_TAG_TYPES.lists,
        ];

        // Mostly used when the include hidden toggle is active on the Search screen to only invalidate hidden athlete ID's instead of the entire cache of athletes
        // For the hidden list screen, we still need similar behavior, but we do need to poperly remove cache for athletes
        if (isIncludeHidden && !isHiddenList) {
          return commonTags;
        }

        return [...commonTags, COACH_X_API_TAG_TYPES.athletes];
      },
      async onQueryStarted(
        { coachID, sportSlug, athleteID },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          searchApi.util.updateQueryData(
            'getAllCoachHiddenAthleteIdsForSport',
            { sportSlug, coachID },
            draft => {
              return draft.filter(id => id !== Number(athleteID));
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    postFollowedAthlete: build.mutation<
      BaseApiOutput,
      PostFollowedAthleteInput
    >({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/athlete/followed`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: () => {
        return [
          COACH_X_API_TAG_TYPES.lists,
          COACH_X_API_TAG_TYPES.athleteActivities,
          COACH_X_API_TAG_TYPES.followedAthleteIds,
          { type: COACH_X_API_TAG_TYPES.athletes, id: SCREENS.lists },
        ];
      },
      async onQueryStarted(
        { coachID, sportSlug, athleteIDs },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          searchApi.util.updateQueryData(
            'getAllCoachFollowedAthleteIdsForSport',
            { coachID, sportSlug },
            draft => {
              const mappedAthleteIDs = athleteIDs.map(id => Number(id));
              draft.concat(mappedAthleteIDs);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteFollowedAthlete: build.mutation<
      BaseApiOutput,
      DeleteFollowedAthleteInput
    >({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/athlete/followed`,
        method: 'DELETE',
        body: JSON.stringify(data),
      }),

      invalidatesTags: () => {
        return [
          COACH_X_API_TAG_TYPES.lists,
          COACH_X_API_TAG_TYPES.athleteActivities,
          COACH_X_API_TAG_TYPES.followedAthleteIds,
          { type: COACH_X_API_TAG_TYPES.athletes, id: SCREENS.lists },
        ];
      },
      async onQueryStarted(
        { coachID, sportSlug, athleteIDs },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          searchApi.util.updateQueryData(
            'getAllCoachFollowedAthleteIdsForSport',
            { sportSlug, coachID },
            draft => {
              const mappedAthleteIDs = athleteIDs.map(id => Number(id));
              return draft.filter(id => !mappedAthleteIDs.includes(id));
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    postAthleteProfileView: build.mutation<
      BaseApiOutput,
      PostAthleteProfileViewInput
    >({
      query: data => ({
        url: `${BASE_STORAGE_PATH}/athlete/viewed`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      invalidatesTags: [COACH_X_API_TAG_TYPES.viewedAthleteIds],
    }),
    postResendVerificationEmail: build.mutation<
      BaseApiOutput,
      PostResendVerificationEmailInput
    >({
      query: ({ authUserID }) => ({
        url: `${BASE_STORAGE_PATH}/identity/verification-email/${authUserID}`,
        method: 'POST',
      }),
    }),
    patchCoachAvatar: build.mutation<undefined, File>({
      query: file => {
        const body = new FormData();
        body.append('Content-Type', file.type);
        body.append('avatar', file);

        return {
          url: `${BASE_STORAGE_PATH}/identity/avatar`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [COACH_X_API_TAG_TYPES.coachProfileImage],
    }),
  }),
});

export const {
  usePostCoachMutation,
  usePutCoachMutation,
  usePatchCoachOnboardMutation,
  useDeleteAthleteNoteMutation,
  usePostAthleteNoteMutation,
  usePatchAthleteNoteMutation,
  usePostSaveSearchMutation,
  usePutSavedSearchMutation,
  useDeleteSavedSearchMutation,
  usePostHiddenAthleteMutation,
  useDeleteHiddenAthleteMutation,
  usePostFollowedAthleteMutation,
  useDeleteFollowedAthleteMutation,
  usePostAthleteProfileViewMutation,
  usePostEmailMutation,
  usePostAthleteAddToFrontRushMutation,
  usePostPreferenceMutation,
  usePatchPreferenceMutation,
  usePostResendVerificationEmailMutation,
  usePostRecruitingNeedsMutation,
  usePatchRecruitingNeedMutation,
  useDeleteRecruitingNeedMutation,
  usePatchCoachAvatarMutation,
} = storageApi;
