import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootState.type';

import { Coach } from 'types/coach.types';

import { initialState } from './coach.slice';
import { CoachState } from './coach.slice.types';

const selectSlice = (state: RootState) => state.coach || initialState;

const isNullCoach = (coach: CoachState) => {
  return coach === null;
};

export const isValidCoach = (coach: CoachState): coach is Coach => {
  if (isNullCoach(coach)) return false;

  return (coach as Coach).id !== undefined;
};

export const selectIsAuthCoach = createSelector([selectSlice], coach => {
  if (isNullCoach(coach)) return false;

  return (
    (coach as Coach).id === undefined && (coach as Coach).email !== undefined
  );
});

export const selectIsCoachValid = createSelector([selectSlice], coach => {
  if (isValidCoach(coach)) {
    return true;
  }
  return false;
});

export const selectIsCoachVerified = createSelector([selectSlice], coach => {
  if (isValidCoach(coach)) {
    return Boolean(coach.verifiedAt);
  }
  return false;
});

export const selectIsCoachDeleted = createSelector([selectSlice], coach => {
  if (isValidCoach(coach)) {
    return Boolean(coach.deletedAt);
  }
  return false;
});

export const selectIsCoachOnboarding = createSelector([selectSlice], coach => {
  if (isValidCoach(coach)) {
    return !coach.onboardedAt;
  }
  return true;
});

export const selectIsCoachOnboarded = createSelector([selectSlice], coach => {
  if (isValidCoach(coach)) {
    return Boolean(coach.onboardedAt);
  }
  return false;
});

export const selectCoachID = createSelector(
  [state => state.coach as Coach],
  coach => coach.id,
);

export const selectCoachCollege = createSelector(
  [state => state.coach as Coach],
  coach => coach.college,
);
