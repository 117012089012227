import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconExternalOpen = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 19"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.3299 2.83655L16.1076 2.83655M16.1076 2.83655V6.60339M16.1076 2.83655L8.55208 10.3702"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="square"
        />
        <path
          d="M8.4375 2.89062H1V18.0017H16.1111V10.5605"
          stroke="white"
          strokeWidth="1.5"
        />
      </svg>
    );
  },
);
IconExternalOpen.displayName = 'IconExternalOpen';
