import { ApiFilter } from 'app/components/Filters/filters.types';

import { FilterGroup, FilterPanelConfiguration } from './filtersState.types';

export const FILTER_GROUP_FILTERS_LIMIT = Infinity;

const compactGroups = ['Athlete Preferences', 'Advanced'];
const isCompactGroup = (name: string) => compactGroups.includes(name);

export const createFilterPanelConfigurations = (
  filters: ApiFilter[],
): FilterPanelConfiguration => {
  return filters.reduce((acc, filter) => {
    if (!filter.groupName) {
      return [...acc, filter.name];
    }

    const groupIndex = acc.findIndex(
      config =>
        typeof config === 'object' && config.heading === filter.groupName,
    );

    if (groupIndex === -1) {
      return [
        ...acc,
        {
          heading: filter.groupName,
          filtersLimit: FILTER_GROUP_FILTERS_LIMIT,
          groupFilters: [filter.name],
          compact: isCompactGroup(filter.groupName),
        },
      ];
    }

    const group = acc[groupIndex] as FilterGroup;

    const updatedGroup = {
      ...group,
      groupFilters: [...group.groupFilters, filter.name],
    } as FilterGroup;

    const updatedConfigurations = [...acc];
    updatedConfigurations[groupIndex] = updatedGroup;

    return updatedConfigurations;
  }, [] as FilterPanelConfiguration);
};
