import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconLocation = forwardRef<SVGSVGElement, IconProps>(
  (
    { stroke = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <path
          id="Vector"
          d="M11.9312 7.3208L7.99935 14.9128L4.06753 7.3208L4.06762 7.32076L4.06274 7.31179C3.11134 5.5631 3.36772 3.31841 4.81972 1.84049C6.5399 0.0941051 9.29692 0.0544593 11.0601 1.71949L11.1788 1.84026C12.6353 3.32253 12.8876 5.56267 11.936 7.31179L11.9359 7.31174L11.9312 7.3208Z"
          stroke={stroke}
        />
        <circle id="Ellipse 522" cx="8" cy="5.5166" r="1.75" stroke={stroke} />
      </svg>
    );
  },
);
IconLocation.displayName = 'IconLocation';
