import { useCheckForUpdates } from 'app/utils/useCheckForUpdates.hook';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

import { Flex, Text } from '@mantine/core';

import { Button } from 'app/components/Button';

import { environment } from 'utils/runtime-environment/RuntimeEnvironment';

import { DEFAULT_IDLE_TIMEOUT_CONFIGURATION } from 'types/idle.types';

import './Maintenance.portal.scss';

export const MaintenancePortal = () => {
  const { version: versionConfig } = environment.env.refreshConfigurations ?? {
    version: DEFAULT_IDLE_TIMEOUT_CONFIGURATION,
  };
  const { isUpdated, handleRefresh } = useCheckForUpdates(versionConfig);

  const showMaintenance = isUpdated;
  const classList = classNames('maintenance-banner', {
    active: showMaintenance,
  });

  if (!showMaintenance) {
    return null;
  }

  return ReactDOM.createPortal(
    <Flex align="center" justify="space-between" className={classList}>
      <Text span>
        We&apos;ve been making some improvements behind the scenes.
      </Text>
      <Button variant="link" onClick={handleRefresh} mt={2}>
        Refresh
      </Button>
    </Flex>,
    document.querySelector('#maintenance')!,
  );
};
