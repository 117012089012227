import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const ICON_EMAIL_TEST_ID = 'icon-email';

export const IconEmail = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={width ?? size}
        height={height ?? size}
        fill={fill}
        {...props}
        ref={forwardedRef}
        data-testid={ICON_EMAIL_TEST_ID}
      >
        <rect
          id="Rectangle 8834"
          x="0.5"
          y="2.5"
          width="15"
          height="11"
          stroke={stroke}
        />
        <path
          id="Vector 42"
          d="M0.666992 2.66699L7.86579 9.21136C7.94207 9.28071 8.05858 9.28071 8.13486 9.21136L15.3337 2.66699"
          stroke={stroke}
        />
        <path
          id="Vector 43"
          d="M0.666992 13.333L6.33366 7.33301"
          stroke={stroke}
        />
        <path
          id="Vector 44"
          d="M15.333 13.333L9.66634 7.33301"
          stroke={stroke}
        />
      </svg>
    );
  },
);
IconEmail.displayName = 'IconEmail';
