import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const ICON_CAMERA_TEST_ID = 'icon-camera';

export const IconCamera = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      fill = 'none',
      stroke = 'currentColor',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        {...props}
        ref={forwardedRef}
        data-testid={ICON_CAMERA_TEST_ID}
      >
        <g id="Group 152">
          <circle
            id="Ellipse 26"
            cx="15"
            cy="15"
            r="14.5"
            fill={fill}
            stroke={stroke}
          />
          <g id="Group 32">
            <path
              id="Vector 16"
              d="M7 20.5V12.8199C7 12.1276 7.47387 11.5252 8.14676 11.3621L9.77264 10.9682C10.2117 10.8618 10.5795 10.5632 10.7737 10.1553L11.3928 8.8551C11.6416 8.33274 12.1685 8 12.7471 8H17.4329C18.0553 8 18.6131 8.38437 18.8347 8.96601L19.2522 10.0621C19.4282 10.524 19.8204 10.8696 20.3007 10.9859L21.8532 11.3621C22.5261 11.5252 23 12.1276 23 12.8199V17.38V20.5C23 21.3284 22.3284 22 21.5 22H18.4667H8.5C7.67157 22 7 21.3284 7 20.5Z"
              fill={fill}
              stroke={stroke}
              strokeLinecap="round"
            />
            <circle
              id="Ellipse 16"
              cx="15.2671"
              cy="15.9219"
              r="3.5"
              stroke={stroke}
            />
          </g>
        </g>
      </svg>
    );
  },
);
IconCamera.displayName = 'IconCamera';
