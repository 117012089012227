import { notifications } from '@mantine/notifications';

import { CONFIGURABLE_FILTER_TYPES } from 'app/components/Filters/filters.config';
import { ConfigurableFilterTypes } from 'app/components/Filters/filters.types';

import { getColorForStatus } from 'utils/notifications';

import { CoachSavedSearch } from 'types/coachSavedSearch.types';

import { deepMergeMappedFiltersToSearchFilters } from './deepMergeMappedFiltersToSearchFilters.function';
import { FilterPanelFilters } from './filtersState.types';

export const formatSavedSearchFilterValueToState = (
  value: string,
  componentType: ConfigurableFilterTypes,
) => {
  switch (componentType) {
    case CONFIGURABLE_FILTER_TYPES.boolean: {
      return JSON.parse(value);
    }
    case CONFIGURABLE_FILTER_TYPES.multiselect: {
      return value.split(',');
    }
    case CONFIGURABLE_FILTER_TYPES.select: {
      return value;
    }
    case CONFIGURABLE_FILTER_TYPES.range:
    case CONFIGURABLE_FILTER_TYPES.rangeSelect: {
      return value.split(',').map(part => parseFloat(part.trim()));
    }
    default: {
      throw Error('Unsupported componentType');
    }
  }
};

const mapSavedSearchFiltersForState = (
  defaultFilters: FilterPanelFilters,
  savedSearchFilters: CoachSavedSearch['filters'],
): Partial<FilterPanelFilters> => {
  return savedSearchFilters.reduce((acc, filter) => {
    const [key] = Object.keys(filter);
    if (defaultFilters[key]) {
      return {
        ...acc,
        [key]: {
          value: formatSavedSearchFilterValueToState(
            filter[key],
            defaultFilters[key].component,
          ),
        },
      };
    }

    notifications.hide(`notification-${key}`);
    notifications.show({
      id: `notification-${key}`,
      message: `Saved filter is not applied: ${key}`,
      color: getColorForStatus('error'),
    });

    return { ...acc };
  }, {});
};

export const applySavedSearchFiltersToState = (
  filters: FilterPanelFilters,
  savedSearch: CoachSavedSearch,
): FilterPanelFilters => {
  const mappedFilters = mapSavedSearchFiltersForState(
    filters,
    savedSearch.filters,
  );

  return deepMergeMappedFiltersToSearchFilters(filters, mappedFilters);
};
