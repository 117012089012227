import { useAuth0 } from '@auth0/auth0-react';
import { LOG_OUT } from 'app/utils/general.constants';
import classNames from 'classnames';

import { Card, Flex } from '@mantine/core';

import { Button } from 'app/components/Button';

import { BaseClassNameProp } from 'types/base.types';

import './StatusMessageDetails.component.scss';

type StatusMessageDetailsProps = BaseClassNameProp & {
  details: string;
};

export const StatusMessageDetails = ({
  details,
  className,
}: StatusMessageDetailsProps) => {
  const { logout } = useAuth0();

  return (
    <Flex
      className={classNames('status-message-details', className)}
      direction="column"
    >
      <Card className="status-message-details__detail" withBorder>
        {details}
      </Card>
      <Button
        variant="link"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        {LOG_OUT}
      </Button>
    </Flex>
  );
};
