import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DEFAULT_SORT_KEY,
  DEFAULT_SORT_KEY_SORT_ORDER,
  SEARCH_PAGE_SIZE,
} from 'app/utils/dataTable.constants';
import { searchApi } from 'services/search.service';

import { Sort, TableColumn } from 'app/components/Table/Table.component.types';

import { Athlete } from 'types/athlete.types';

import { coachSportsActions } from '../coachSports/coachSports.slice';
import { DataTableState } from '../shared/dataTableState.types';

export const baseInitialState = {
  skip: 0,
  size: SEARCH_PAGE_SIZE,
  sortKey: DEFAULT_SORT_KEY,
  sortOrder: DEFAULT_SORT_KEY_SORT_ORDER,
  selected: {},
};

export const initialState: DataTableState = {
  ...baseInitialState,
  columns: [],
};

const resetBaseState = (state: DataTableState) => ({
  ...state,
  ...baseInitialState,
});

const slice = createSlice({
  name: 'listDataTable',
  initialState,
  reducers: {
    setPage(state, { payload: { skip } }: PayloadAction<{ skip: number }>) {
      state.skip = skip;
      state.selected = initialState.selected;
    },
    setSort(state, { payload: { sortKey, sortOrder } }: PayloadAction<Sort>) {
      state.sortKey = sortKey;
      state.sortOrder = sortOrder;
      state.selected = initialState.selected;
    },
    select(state, { payload: selected }: PayloadAction<Athlete[]>) {
      selected.forEach((athlete: Athlete) => {
        const { athleteID } = athlete;

        if (!state.selected[athleteID]) {
          state.selected[athleteID] = athlete;
        }
      });
    },
    unselect(state, { payload: selectedIds }: PayloadAction<number[]>) {
      selectedIds.forEach(athleteID => {
        delete state.selected[athleteID];
      });
    },
    resetSelected(state) {
      state.selected = {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(coachSportsActions.setCoachSelectedSport, resetBaseState)
      .addMatcher(
        searchApi.endpoints.getListColumnsBySport.matchFulfilled,
        (state, { payload }: PayloadAction<TableColumn<Athlete>[]>) => {
          state.columns = payload;
        },
      );
  },
});

export const { actions: listDataTableActions } = slice;

export default slice.reducer;
