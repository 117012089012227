import { forwardRef } from 'react';

import { Button, LinkButtonProps } from 'app/components/Button';
import { IconTriangleDown } from 'app/components/Icons';

import './SportSelectorButton.component.scss';

interface SportSelectorButtonProps extends LinkButtonProps {
  selectedSportName: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
export const SportSelectorButton = forwardRef<
  HTMLButtonElement,
  SportSelectorButtonProps
>(({ selectedSportName, onClick, ...rest }: SportSelectorButtonProps, ref) => {
  return (
    <Button
      {...rest}
      ref={ref}
      variant="link"
      rightSection={
        <IconTriangleDown size={9} className="sport-selector-button__icon" />
      }
      className="sport-selector-button"
      aria-labelledby="sport-selector-button__label"
      onClick={onClick}
    >
      <span id="sport-selector-button--label">Sport: {selectedSportName}</span>
    </Button>
  );
});
SportSelectorButton.displayName = 'SportSelectorButton';
