import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconTriangleRight = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={width ?? size}
        height={height ?? size}
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path d="M14 7.99991L4 16L4 -9.98597e-07L14 7.99991Z" fill={fill} />
      </svg>
    );
  },
);
