import { forwardRef } from 'react';

import { IconProps } from '../icons.component.types';

export const IconHudl = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 16, width, height, ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
      >
        <mask
          id="mask0_6028_68502"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="30"
          height="30"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0.00830078V30.0001H29.9921V0.00830078L0 0.00830078Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_6028_68502)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.9921 15.0041C29.9921 23.2862 23.2781 30.0001 14.9962 30.0001C6.71391 30.0001 0 23.2862 0 15.0041C0 6.72216 6.71391 0.00830078 14.9962 0.00830078C23.2781 0.00830078 29.9921 6.72216 29.9921 15.0041Z"
            fill="#F05A28"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.17981 10.6906C5.2314 10.5202 5.28487 10.3502 5.3343 10.1791C5.83204 8.45585 7.6627 7.49176 9.4678 7.9792C9.68542 8.03802 9.95138 7.99345 10.1769 7.9318C10.4297 7.86279 10.6809 7.74794 10.8996 7.60373C11.475 7.22439 12.0401 7.30412 12.6533 7.51782C13.5686 7.83681 14.3673 8.31465 15.1079 9.02422C14.8068 9.16704 14.5512 9.29745 14.2883 9.41136C13.5448 9.73357 12.799 10.0507 12.0524 10.3661C11.8599 10.4475 11.7368 10.5317 11.7552 10.7901C11.8553 12.191 11.0291 13.4271 9.66221 13.9093C9.36913 14.0128 9.20169 14.1781 9.07453 14.442C8.27334 16.1054 8.16845 17.8195 8.70027 19.6405C8.60277 19.5869 8.54332 19.5688 8.50413 19.5304C7.22269 18.2764 6.44143 16.7824 6.29006 14.9876C6.28187 14.8908 6.25311 14.7941 6.25696 14.6983C6.29249 13.8119 6.14163 12.9985 5.54061 12.2788C5.32574 12.0215 5.29472 11.6135 5.17981 11.2743V10.6906Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4342 6.25928C16.6814 6.3061 16.9282 6.35531 17.176 6.39932C18.3627 6.61034 19.4421 7.09231 20.4937 7.65654C20.6867 7.76007 20.9441 7.81404 21.1622 7.79307C23.0897 7.60739 24.6047 8.61206 24.9661 10.3774C25.1602 11.326 24.9587 12.2392 24.2552 12.9074C23.7645 13.3732 23.5783 13.875 23.5909 14.4884C23.5991 14.8905 23.4115 15.1798 23.1697 15.4636C22.5208 16.2259 21.2273 17.0102 20.1562 17.2665C20.182 16.8451 20.203 16.4346 20.2331 16.025C20.2783 15.4098 20.3231 14.7944 20.382 14.1804C20.4051 13.9399 20.3246 13.8074 20.1056 13.6832C18.7397 12.9079 18.2084 11.716 18.4858 10.1688C18.5163 9.99897 18.4609 9.7724 18.362 9.62814C17.2791 8.04793 15.7977 7.0414 13.8999 6.65089C13.8326 6.63707 13.7676 6.61262 13.5972 6.56243C14.2424 6.44938 14.7847 6.3543 15.3269 6.25928H16.4342Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3094 16.8345C22.8015 19.032 21.601 20.6141 19.8474 21.8031C19.7544 21.8662 19.6646 21.9361 19.5654 21.9875C18.8993 22.3328 18.4212 22.8004 18.0394 23.5008C17.3256 24.8104 16.0571 25.1725 14.642 24.9929C13.3967 24.835 12.3619 23.7967 12.0311 22.4875C11.9853 22.3061 11.8246 22.1417 11.6862 22.0001C11.5709 21.8821 11.4203 21.7849 11.2678 21.7202C10.339 21.3263 10.0499 20.5049 9.96645 19.617C9.89078 18.8118 9.95045 17.9938 9.95045 17.1061C10.1031 17.1953 10.2081 17.2477 10.3036 17.3141C11.1809 17.9227 12.0604 18.5285 12.9301 19.148C13.1293 19.2899 13.2756 19.2989 13.4936 19.1691C14.839 18.368 16.3255 18.5169 17.5215 19.5105C17.6841 19.6456 17.9829 19.7034 18.1997 19.6705C20.2099 19.3646 22.0025 18.6331 23.3094 16.8345Z"
          fill="#FEFEFE"
        />
      </svg>
    );
  },
);
IconHudl.displayName = 'IconHudl';
