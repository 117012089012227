import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconEmailUnverified = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      stroke = 'currentColor',
      fill = 'none',
      size = 16,
      width,
      height,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 77 77"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        fill={fill}
        {...props}
        ref={forwardedRef}
      >
        <circle
          cx="38.5"
          cy="38.5"
          r="33.5"
          fill="#7A7775"
          stroke="#CECAC8"
          strokeWidth="10"
        />
        <rect
          x="24"
          y="27"
          width="30.7586"
          height="23"
          rx="2"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M23.8621 26.8621L37.7348 39.6001C38.9065 40.676 40.7144 40.6502 41.855 39.5413L54.8965 26.8621"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M23.8621 50.1379L36.5366 38.5M54.8965 50.1379L42.9261 38.5"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
IconEmailUnverified.displayName = 'IconEmailUnverified';
