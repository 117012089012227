import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconAngleUp = forwardRef<SVGSVGElement, IconProps>(
  (
    { stroke = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 14 8"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        {...props}
        ref={forwardedRef}
      >
        <path d="M1 7L7 1L13 7" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  },
);
IconAngleUp.displayName = 'IconAngleUp';
