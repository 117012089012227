/**
 *
 * HeaderActions Component
 *
 */
import { Flex } from '@mantine/core';

import './HeaderActions.component.scss';

interface HeaderActionsProps {
  children: React.ReactNode;
}

export const HeaderActions = ({ children }: HeaderActionsProps) => {
  return (
    <Flex align="center" className="main-header-action-menu">
      {children}
    </Flex>
  );
};
