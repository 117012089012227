/**
 *
 * CoachAvatar Component
 *
 */
import { AVATAR_SIZES } from 'app/utils/avatar.constants';
import classNames from 'classnames';
import { useGetCoachProfileImageURLQuery } from 'services/search.service';

import {
  Avatar,
  AvatarProps,
  Box,
  LoadingOverlayProps,
  MantineSize,
  OverlayProps,
  rem,
} from '@mantine/core';

import { IconUser } from 'app/components/Icons';

import { Coach } from 'types/coach.types';

import './CoachAvatar.component.scss';

import { LoadingOverlay } from '../LoadingOverlay';
import { CoachAvatarImageUploader } from './CoachAvatarImageUploader';

const DEFAULT_AVATAR_OVERLAY_PROPS = { radius: 'md', blur: 1 } as OverlayProps;
const DEFAULT_RADIUS = 999;

interface CoachAvatarProps extends AvatarProps {
  coach: Coach;
  size?: number | MantineSize | (string & unknown);
  onClick?: () => void;
  isUploader?: boolean;
  loadingOverlayProps?: LoadingOverlayProps;
}

export const CoachAvatar = ({
  coach,
  children,
  size = AVATAR_SIZES.profile,
  onClick,
  loadingOverlayProps,
  isUploader = false,
  ...props
}: CoachAvatarProps) => {
  const { firstName, lastName } = coach;
  const {
    data: avatarURL,
    isLoading,
    isFetching,
    isError,
  } = useGetCoachProfileImageURLQuery();

  const avatarLoading = isLoading || isFetching;
  const overlayProps = {
    ...DEFAULT_AVATAR_OVERLAY_PROPS,
    ...loadingOverlayProps?.overlayProps,
  } as OverlayProps;
  const avatarContainerSize = rem(size);
  const title = `${firstName} ${lastName} Thumbnail`;
  const classList = classNames('coach-avatar', {
    'coach-avatar--loading': avatarLoading,
  });

  if (isError) {
    return (
      <Box
        pos="relative"
        className={classList}
        style={{ height: avatarContainerSize, width: avatarContainerSize }}
      >
        <Avatar
          {...props}
          size={size}
          src={avatarURL}
          title={title}
          alt="Coach Thumbnail"
          variant="outline"
          radius={DEFAULT_RADIUS}
          onClick={onClick}
        >
          <IconUser size={size} />
        </Avatar>
      </Box>
    );
  }

  return (
    <Box
      pos="relative"
      className={classList}
      style={{ height: avatarContainerSize, width: avatarContainerSize }}
    >
      <LoadingOverlay
        {...loadingOverlayProps}
        overlayProps={overlayProps}
        zIndex={1000}
        visible={avatarLoading}
      />
      <Avatar
        {...props}
        size={size}
        src={avatarURL}
        title={title}
        alt="Coach Thumbnail"
        variant="outline"
        radius={DEFAULT_RADIUS}
        onClick={onClick}
      >
        <IconUser size={size} />
      </Avatar>
      {isUploader ? <CoachAvatarImageUploader useMenu={!!avatarURL} /> : null}
    </Box>
  );
};
