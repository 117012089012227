import { createTheme } from '@mantine/core';

/**
 * This file is used to configured the MantineProvider: https://mantine.dev/theming/mantine-provider/
 *
 * This facilitates getting our 'theme' to the current approved design for some colors
 * and typography.
 *
 * This file overrides some of the provided styling and theming within Mantine and should rarely
 * need modification as any additional style configurations should be handled at the component-level
 * through our process of using scss.
 *
 * If access to the theme is needed within the SCSS files of our components, we have configured
 * the MantineProvider to expose the CSS Variables from mantine (which are modified with the below overrides)
 * and can be utilized via: https://mantine.dev/theming/mantine-provider/#css-variables
 */
export const theme = createTheme({
  // Brand Colors
  colors: {
    blue: [
      'var(--coachx-blue-100)',
      'var(--coachx-blue-90)',
      'var(--coachx-blue-80)', // Primary
      'var(--coachx-blue-70)',
      'var(--coachx-blue-60)',
      'var(--coachx-blue-50)',
      'var(--coachx-blue-40)',
      'var(--coachx-blue-30)',
      'var(--coachx-blue-20)',
      'var(--coachx-blue-10)',
    ],
    'medium-blue': [
      'var(--coachx-tertiary-medium-blue-100)',
      '#1e6494',
      '#3775a0',
      '#5087ac',
      '#6998b8',
      '#82a9c4',
      '#9bbacf',
      '#b4cbdb',
      '#cddde7',
      '#e6eef3',
    ],
    'dark-blue': [
      'var(--coachx-blue-100)',
      '#1a426f',
      '#33577f',
      '#4d6c8f',
      '#66819f',
      '#8096af',
      '#99abbf',
      '#b3c0cf',
      '#ccd5df',
      '#e6eaef',
    ],
    gray: [
      'var(--coachx-primary-black-100)',
      'var(--coachx-gray-90)',
      'var(--coachx-gray-80)',
      'var(--coachx-gray-70)',
      'var(--coachx-gray-60)',
      'var(--coachx-gray-50)',
      'var(--coachx-gray-40)',
      'var(--coachx-gray-30)',
      'var(--coachx-gray-20)',
      'var(--coachx-gray-10)',
    ],
    tertiary: [
      'var(--coachx-tertiary-green-100)',
      '#d0df3f',
      '#d5e255',
      '#dbe66a',
      '#e0e97f',
      '#e5ed95',
      '#eaf1aa',
      '#eff4bf',
      '#f5f8d4',
      '#fafbea',
    ],
    success: [
      'var(--coachx-semantic-success-100)',
      '#82b85c',
      '#90c06e',
      '#9ec880',
      '#acd092',
      '#bad8a5',
      '#c7dfb7',
      '#d5e7c9',
      '#e3efdb',
      '#f1f7ed',
    ],
    warning: [
      'var(--coachx-semantic-warning-100)',
      '#f1e686',
      '#f2e993',
      '#f4eba1',
      '#f5eeae',
      '#f7f1bc',
      '#f9f4c9',
      '#faf7d7',
      '#fcf9e4',
      '#fdfcf2',
    ],
    error: [
      'var(--coachx-semantic-error-100)',
      'var(--coachx-semantic-error-90)',
      '#ac3636',
      '#b54d4d',
      '#bf6363',
      '#c87979',
      '#d19090',
      '#daa6a6',
      '#e3bcbc',
      '#edd2d2',
    ],
  },
  white: 'var(--coachx-primary-white-100)',
  black: 'var(--coachx-primary-black-100)',

  // focus styles applied to components that are based on Input
  // styled are added with &:focus selector
  focusClassName: 'focus-ring--active',
});
