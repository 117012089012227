import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { CoachNoSports, CoachState } from './coach.slice.types';

export const initialState = null as CoachState;

const slice = createSlice({
  name: 'coach',
  initialState,
  reducers: {
    setCoach: (_, { payload: coach }: PayloadAction<CoachState>) => {
      return coach;
    },
    setCoachOnboardedAt: (
      state,
      {
        payload,
      }: PayloadAction<{
        value: string;
      }>,
    ) => {
      (state as CoachNoSports).onboardedAt = payload.value;
    },
  },
});

export const { actions: coachActions } = slice;

export const useCoachSlice = () => {
  return { actions: slice.actions };
};

export default slice.reducer;
