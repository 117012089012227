import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { searchApi } from 'services/search.service';

import { SearchCoachSportFiltersQueryOutput } from 'types/search.types';

import { applySavedSearchFiltersToState } from '../shared/applySavedSearchFiltersToState.function';
import { createFilterPanelConfigurations } from '../shared/createFilterPanelConfigurations.function';
import { FilterPanelFilters } from '../shared/filtersState.types';
import { mapDefaultSportFiltersToState } from '../shared/mapDefaultSportFiltersToState.function';
import {
  SearchFiltersState,
  SearchFiltersStateWithoutQuery,
} from './searchFilters.slice.types';

export const initialState = {
  query: '',
  default: {},
  current: {},
  configuration: {},
} as SearchFiltersState;

const slice = createSlice({
  name: 'searchFilters',
  initialState,
  reducers: {
    setFilterValue(
      state,
      action: PayloadAction<{
        name: string;
        value: any;
      }>,
    ) {
      const { name, value } = action.payload;
      if (state.current[name]) {
        state.current[name]!.value = value;
      }
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
    setFilters(
      state,
      { payload }: PayloadAction<{ filters: FilterPanelFilters }>,
    ) {
      state.current = payload.filters;
    },
    resetFilters(state) {
      state.current = state.default;
    },
    resetFilter(
      state,
      { payload: { filter } }: PayloadAction<{ filter: string }>,
    ) {
      const defaultFilterState = state.default[filter];

      if (defaultFilterState) {
        state.current[filter] = defaultFilterState;
      }
    },
    setInitialState(
      state,
      { payload }: PayloadAction<SearchFiltersStateWithoutQuery>,
    ) {
      state.default = payload.default;
      state.current = payload.current;
      state.configuration = payload.configuration;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      searchApi.endpoints.getCoachSportSearchFilters.matchFulfilled,
      (
        state: SearchFiltersState,
        { payload }: PayloadAction<SearchCoachSportFiltersQueryOutput>,
      ) => {
        const { defaultSportFilters, defaultSavedSearch } = payload;
        const mappedDefaultSportFilters =
          mapDefaultSportFiltersToState(defaultSportFilters);

        const filterPanelConfiguration =
          createFilterPanelConfigurations(defaultSportFilters);

        let currentSportFilters: FilterPanelFilters;
        if (!defaultSavedSearch) {
          currentSportFilters = mappedDefaultSportFilters;
        } else {
          const filtersWithAppliedSavedSearch = applySavedSearchFiltersToState(
            mappedDefaultSportFilters,
            defaultSavedSearch,
          );
          currentSportFilters = filtersWithAppliedSavedSearch;
        }

        state.current = currentSportFilters;
        state.default = mappedDefaultSportFilters;
        state.configuration = filterPanelConfiguration;
      },
    );
  },
});

export const { actions: searchFiltersActions } = slice;

export const useSearchFiltersSlice = () => {
  return { actions: slice.actions };
};

export default slice.reducer;
