import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconBar = forwardRef<SVGSVGElement, IconProps>(
  (
    { stroke = 'currentColor', size = 16, width, height, className, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        stroke={stroke}
        {...props}
        ref={forwardedRef}
      >
        <line
          x1="0.5"
          y1="1.56863"
          x2="23.4286"
          y2="1.56863"
          strokeLinecap="round"
        />
        <line
          x1="0.5"
          y1="12.2353"
          x2="23.4286"
          y2="12.2353"
          strokeLinecap="round"
        />
        <line
          x1="0.5"
          y1="22.9019"
          x2="23.4286"
          y2="22.9019"
          strokeLinecap="round"
        />
      </svg>
    );
  },
);
IconBar.displayName = 'IconBar';
