import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const ICON_DOTS_TEST_ID = 'icon-dots';

export const IconDots = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        width={width ?? size}
        height={height ?? size}
        {...props}
        ref={forwardedRef}
        data-testid={ICON_DOTS_TEST_ID}
      >
        <ellipse
          cx="12"
          cy="4"
          rx="2"
          ry="2"
          transform="rotate(90 12 4)"
          fill={fill}
        />
        <ellipse
          cx="12"
          cy="12"
          rx="2"
          ry="2"
          transform="rotate(90 12 12)"
          fill={fill}
        />
        <ellipse
          cx="12"
          cy="20"
          rx="2"
          ry="2"
          transform="rotate(90 12 20)"
          fill={fill}
        />
      </svg>
    );
  },
);
IconDots.displayName = 'IconDots';
