/**
 *
 * HeaderLogo Component
 *
 */
import { Link } from 'react-router-dom';

import { Box, VisuallyHidden } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { IconAscender, IconAscenderFull } from 'app/components/Icons';

import { Breakpoints } from 'utils/breakpoints';

import './HeaderLogo.component.scss';

type LogoColors = 'blue' | 'white' | (string & unknown);
interface HeaderLogoProps {
  color?: LogoColors;
}

export const HeaderLogo = ({ color = 'blue' }: HeaderLogoProps) => {
  const isDesktop = useMediaQuery(`(min-width: ${Breakpoints.tablet})`);

  const logoProps = {
    fill: `var(--coachx-primary-${color}-100)`,
    role: 'img',
    'aria-labelledby': 'home-link',
  };

  return (
    <Box className="main-header-logo">
      <Link id="home-link" to="/" aria-label="NCSA Coach">
        <VisuallyHidden>Welcome to NCSA College Recruiting</VisuallyHidden>
        {isDesktop ? (
          <IconAscenderFull width={150} height={60} {...logoProps} />
        ) : (
          <IconAscender width={50} height={50} {...logoProps} />
        )}
      </Link>
    </Box>
  );
};
