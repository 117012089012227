import { SetupStepsFormValues } from './SetupSteps.context';

export const SETUP_STEP_MAX_WIDTH = 960;

export const SETUP_FORM_FIELDS = {
  preferenceID: 'preferenceID',
  recruitingNeedID: 'recruitingNeedID',
  minGPA: 'minGPA',
  gradYear: 'gradYear',
  states: 'states',
  positions: 'positions',
  savedSearchName: 'savedSearchName',
} as const;

export const SETUP_FORM_ERROR_MESSAGES = {
  [SETUP_FORM_FIELDS.minGPA]: 'Please select a minimum GPA.',
  [SETUP_FORM_FIELDS.gradYear]: 'Please answer the question above.',
  [SETUP_FORM_FIELDS.states]: 'Please select at least one location.',
  [SETUP_FORM_FIELDS.positions]: 'Please select at least one position.',
};

export const SETUP_FORM_INITIAL_VALUES: SetupStepsFormValues = {
  [SETUP_FORM_FIELDS.recruitingNeedID]: '',
  [SETUP_FORM_FIELDS.preferenceID]: '',
  [SETUP_FORM_FIELDS.minGPA]: '',
  [SETUP_FORM_FIELDS.gradYear]: '',
  [SETUP_FORM_FIELDS.states]: [],
  [SETUP_FORM_FIELDS.positions]: [],
  [SETUP_FORM_FIELDS.savedSearchName]: '',
};

export const SETUP_STEP_TEST_ID = (step: string) => `setup-step-${step}`;

export const NEXT_BUTTON_ARIA_LABEL = 'Go to next step';
export const FINISH_BUTTON_ARIA_LABEL = 'Finish setup';
export const PREV_BUTTON_ARIA_LABEL = 'Go to prev step';

export const CLASS_LIST = {
  root: 'setup-carousel',
  viewport: 'setup-carousel__viewport',
  container: 'setup-carousel__container',
  slide: 'setup-carousel__slide',
  controls: 'setup-carousel__controls',
  indicators: 'setup-carousel__indicators',
  indicator: 'setup-carousel__indicator',
};
