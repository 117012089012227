/**
 *
 * HeaderDrawer Component
 *
 */
import { MAIN_NAV_LINKS as NAV_LINKS } from 'app/utils/navigation.constants';
import { useOnWindowResize } from 'hooks/useOnWindowResize';
import { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Box, Drawer, Flex, UnstyledButton } from '@mantine/core';

import { IconAngleLeft, IconBar } from 'app/components/Icons';

import './HeaderDrawer.component.scss';

import { HeaderLogo } from '../HeaderLogo';

interface DrawerHeaderTitleProps {
  onClose: () => any;
}

const DrawerHeaderTitle = ({ onClose }: DrawerHeaderTitleProps) => {
  return (
    <Flex w="100%" align="center" className="main-header-drawer__title">
      <UnstyledButton
        onClick={onClose}
        className="main-header-drawer__button main-header-drawer__button--close"
      >
        <IconAngleLeft size={20} />
      </UnstyledButton>
      <HeaderLogo />
    </Flex>
  );
};

export const HeaderDrawer = () => {
  const [opened, setOpened] = useState(false);

  useOnWindowResize(useCallback(() => setOpened(false), []));

  const toggleOpen = () => setOpened(() => !opened);

  return (
    <Box className="main-header-drawer">
      <UnstyledButton
        aria-labelledby="main-header-drawer__button-label"
        className="main-header-drawer__button main-header-drawer__button--open"
        onClick={toggleOpen}
      >
        <IconBar size={20} aria-hidden="true" focusable="false" />
        <span id="main-header-drawer__button-label" hidden>
          Open Drawer
        </span>
      </UnstyledButton>
      <Drawer
        size="100%"
        opened={opened}
        onClose={toggleOpen}
        title={<DrawerHeaderTitle onClose={toggleOpen} />}
        withOverlay={false}
        withCloseButton={false}
      >
        <Flex direction="column" className="main-header-drawer__content">
          {NAV_LINKS.map(({ label, ...rest }) => {
            return (
              <NavLink key={label} {...rest} onClick={toggleOpen}>
                {label}
              </NavLink>
            );
          })}
        </Flex>
      </Drawer>
    </Box>
  );
};
