/**
 *
 * Router component
 *
 */
import { useAppSelector } from 'hooks/useAppSelector';
import { useAuth0EmailVerified } from 'hooks/useAuth0EmailVerified';
import { useEffect } from 'react';
import { useNavigate, useRoutes, useSearchParams } from 'react-router-dom';

import {
  selectIsAuthCoach,
  selectIsCoachDeleted,
  selectIsCoachOnboarding,
  selectIsCoachVerified,
} from 'store/slices/coach/coach.slice.selectors';

import { SEARCH_PARAMS } from './Router.constants';
import { unverifiedRoutes, verifiedRoutes } from './router.config';

const useOnboardingRedirects = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isCoachOnboarding = useAppSelector(selectIsCoachOnboarding);
  const step = searchParams.get(SEARCH_PARAMS.step);

  useEffect(() => {
    if (isCoachOnboarding) {
      navigate(`/setup${step ? `?${SEARCH_PARAMS.step}=${step}` : ''}`);
    }
  }, [isCoachOnboarding, navigate, step]);
};

const useUnverifiedRedirects = () => {
  const navigate = useNavigate();
  const isCoachEmailVerified = useAuth0EmailVerified();
  const isCoachDeleted = useAppSelector(selectIsCoachDeleted);
  const isAuthCoach = useAppSelector(selectIsAuthCoach);

  useEffect(() => {
    if (isCoachDeleted) {
      navigate('/denied');
    } else if (isAuthCoach) {
      navigate('/signup');
    } else if (!isCoachEmailVerified) {
      navigate('/email-verification');
    } else {
      navigate('/verification');
    }
  }, [navigate, isCoachEmailVerified, isCoachDeleted, isAuthCoach]);
};

const VerifiedRouter = () => {
  useOnboardingRedirects();
  return useRoutes(verifiedRoutes);
};

const UnverifiedRouter = () => {
  useUnverifiedRedirects();

  return useRoutes(unverifiedRoutes);
};

export const Router = () => {
  const coach = useAppSelector(state => state.coach);
  const isCoachVerified = useAppSelector(selectIsCoachVerified);
  const isCoachDeleted = useAppSelector(selectIsCoachDeleted);
  const isCoachEmailVerified = useAuth0EmailVerified();

  if (!coach) return null;

  if (!isCoachVerified || !isCoachEmailVerified || isCoachDeleted) {
    return <UnverifiedRouter />;
  }

  return <VerifiedRouter />;
};
