import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INTERESTED_SEARCH_PAGE_SIZE } from 'app/utils/search.constants';

import {
  ALL_VALUE,
  DefaultFilters,
} from 'app/components/Filters/filters.types';

import { InterestedSearchState } from './interestedSearch.slice.types';

export const baseInitialState = {
  page: 1,
  skip: 0,
  size: INTERESTED_SEARCH_PAGE_SIZE,
  gradYear: ALL_VALUE,
};
export const initialState: InterestedSearchState = {
  ...baseInitialState,
};

const slice = createSlice({
  name: 'interestedSearch',
  initialState,
  reducers: {
    setFilterValue(
      state,
      action: PayloadAction<{
        name: DefaultFilters;
        value: any;
      }>,
    ) {
      const { name, value } = action.payload;

      if (state[name]) {
        state[name]! = value;
        state.page = baseInitialState.page;
        state.skip = baseInitialState.skip;
      }
    },
    setPagination(
      state,
      {
        payload: { page, skip },
      }: PayloadAction<{
        page: InterestedSearchState['page'];
        skip: InterestedSearchState['skip'];
      }>,
    ) {
      state.page = page;
      state.skip = skip;
    },
    reset: () => initialState,
  },
});

export const { actions: interestedSearchActions } = slice;

export const useInterestedSearchSlice = () => {
  return { actions: slice.actions };
};

export default slice.reducer;
