/**
 *
 * HeaderNav Component
 *
 */
import { MAIN_NAV_LINKS as NAV_LINKS } from 'app/utils/navigation.constants';
import { NavLink } from 'react-router-dom';

import './HeaderNav.component.scss';

export const HeaderNav = () => {
  return (
    <nav className="main-nav">
      {NAV_LINKS.map(({ label, ...rest }) => {
        return (
          <NavLink key={label} {...rest}>
            {label}
          </NavLink>
        );
      })}
    </nav>
  );
};
