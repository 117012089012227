import { forwardRef } from 'react';

import { IconProps } from './icons.component.types';

export const IconTriangleDown = forwardRef<SVGSVGElement, IconProps>(
  (
    { fill = 'currentColor', size = 16, width, height, ...props },
    forwardedRef,
  ) => {
    return (
      <svg
        role="graphics-symbol"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 10"
        width={width ?? size}
        height={height ?? size}
        fill="none"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M5.00006 8.75L1.70301e-06 2.5L10 2.5L5.00006 8.75Z"
          fill={fill}
        />
      </svg>
    );
  },
);
